import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TokenStorage } from '../login/tokenstorage.service';
import { LogHeader } from '../models/logheader';
import { Incident } from '../models/incident';
import { IncidentReport } from '../models/incidentreport';
import { DataService } from '../data.service';
import { LogHeaderService } from './logheader.service';
import { Lookup } from '../models/lookup';
import { LookupWithParent } from '../models/lookupwithparent';
import { UserProfile } from 'src/app/models/userprofile';
import * as moment from 'moment';
import { MatSnackBar, transformMenu } from '@angular/material';
import { DataTransport } from '../datatransport.provider';
import { ConsoleLoggerService } from '../components/console-logger.service';
import { environment } from '../../environments/environment';
import { ReportParameter } from '../models/reportparameter';

@Component({
  moduleId: module.id,
  selector: 'incident-form',
  templateUrl: 'incident.component.html',
  styleUrls: ['logheader.component.css']
})
export class IncidentComponent {
  title = '';
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  item: Incident = null;
  itemReports: IncidentReport[] = [];
  categoryList: LookupWithParent[] = [];
  subCategoryList: LookupWithParent[] = [];
  subCategoryFilteredList: LookupWithParent[] = [];
  userList: Lookup[] = [];
  geoPositionLabel = '';
  userName = '';
  currentDate: number = Date.now();
  categoryFilter = '';
  subcategoryFilter = '';
  reportingDayMismatch = false;
  showMapFlag = false;
  staticMapTemplate: any = '';
  geoPosition = '';

  constructor(
    private router: Router,
    private token: TokenStorage,
    private dataService: DataService,
    private logHeaderService: LogHeaderService,
    private location: Location,
    private snackBar: MatSnackBar,
    private data: DataTransport,
    private route: ActivatedRoute,
    private logger: ConsoleLoggerService
  ) {
    this.userName = this.token.getUser().firstName + ' ' + this.token.getUser().lastName;
  }

  ngOnInit(): void {
    this.item = new Incident();
    this.item.primaryUserId = this.token.getUser().userId;
    this.item.incidentTime = moment(this.currentDate).format("HH:mm");
    this.item.fk_LogHeaderId = 0;
    this.categoryFilter = this.token.getUser().departmentId.toString();

    this.getLogHeader();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getLogHeader(): void {
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
        this.getIncidentCategoryList();
        this.getIncidentSubCategoryList();
        this.getUsersList();
        this.getIncident();
      },
      error => {
        this.logger.error(error);
        this.router.navigate(['/log/header']);
      });
  }

  getIncidentCategoryList(): void {
    this.dataService.getListIncidentCategoriesLookups(this.token.getUser().departmentId.toString())
      .subscribe(
      itms => {
        if (itms != null) {
          itms.unshift({ id: '', label: '', parentId: '0', parentLabel: '' });
          this.categoryList = itms;
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.categoryList = [];
        this.logger.error(error);
      });
  }

  getIncidentSubCategoryList(): void {
    this.dataService.getListIncidentSubCategoriesLookups(this.token.getUser().departmentId.toString())
      .subscribe(
      itms => {
        if (itms != null) {
          itms.unshift({ id: '', label: '', parentId: '0', parentLabel: '' });
          this.subCategoryList = itms;
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.subCategoryList = [];
        this.logger.error(error);
      });
  }

  getUsersList(): void {
    this.dataService.getUserLookups()
      .subscribe(
      itms => {
        if (itms != null) this.userList = itms;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.userList = [];
        this.logger.error(error);
      });
  }

  setGeoLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {

        this.item.latitude = position.coords.latitude;
        this.item.longitude = position.coords.longitude;

        this.geoPositionLabel = this.item.latitude + "," + this.item.longitude;
        this.geoPosition = this.item.latitude + "," + this.item.longitude;

      });
    } else {
      this.item.latitude = null;
      this.item.longitude = null;

      this.geoPositionLabel = "Geolocation is not supported by this browser.";
      this.geoPosition = '';
    }
    this.logger.log(this.geoPositionLabel);
  }

  getIncident(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id === 0) {
      let dt1 = moment(new Date()).startOf('day');
      let dt2 = moment(this.logHeader.reportingDay).startOf('day');
      if (dt1.toISOString() != dt2.toISOString()) {
        this.reportingDayMismatch = true;
      }
      return;
    }
    this.dataService.getIncidentById(id)
      .subscribe(
      itms => {
        if (itms != null) {
          this.item = itms;
          if (this.item.latitude == null || this.item.longitude == null) {
            this.geoPositionLabel = "Geolocation not set";
            this.geoPosition = '';
          } else {
            this.geoPositionLabel = this.item.latitude + "," + this.item.longitude;
            this.geoPosition = this.item.latitude + "," + this.item.longitude;
          }
          this.logHeaderService.setReportingDay(moment(this.item.reportingDay).toDate());
          this.changeSubCategoryFilter(this.item.incidentCategory);
          this.getIncidentReports();
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        this.logger.error(error);
      });
  }

  getIncidentReports(): void {
    if (this.item.logIncidentId === 0)
      return;
    this.dataService.getIncidentReports(this.item.logIncidentId)
      .subscribe(
      itms => {
        if (itms != null && itms.length > 0) {
          this.itemReports = itms;
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.itemReports = null;
        this.logger.error(error);
      });
  }

  saveForm(): void {

    var flag: boolean;
    if (this.item.fk_LogHeaderId === 0) {
      this.item.fk_LogHeaderId = this.logHeader.logHeaderId;
    }
    var item = this.dataService.saveIncident(this.item as Incident)
      .subscribe(result => {
        // Handle result
        //console.log(result);
        //this.goBack();
        this.item = result;
        this.openSnackBar("Update succesful", "Incident");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.logger.error(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }


  changeSubCategoryFilter(e) {
    this.subcategoryFilter = '';
    for (var i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].label == e) {
        this.subcategoryFilter = this.categoryList[i].id;
        break;
      }
    }
    this.resetSubCategoryList();
  }

  resetSubCategoryList() {
    this.subCategoryFilteredList = this.subCategoryList.filter(x => x.parentId == this.subcategoryFilter);
    if (this.item.incidentSubCategory && this.subCategoryFilteredList.filter(x => x.label == this.item.incidentSubCategory).length == 0) {
      this.item.incidentSubCategory = '';
    }
  }

  goToReport(id: number): void {
    if (id === 0) {
      var url = '/log/incident/' + this.item.logIncidentId + '/report';
    } else {
      var url = '/log/incident/' + this.item.logIncidentId + '/report/' + id;
    }
    this.data.storage = this.item;
    this.router.navigate([url]);
  }

  toggleMap(): void {
    if (this.showMapFlag == true) {
      this.showMapFlag = false;
    } else {
      if (this.geoPosition != '') {
        if (this.staticMapTemplate == '') {
          let lbl = this.token.getUser().departmentCode.substr(0, 1);
          let key = environment.mapApi.key;
          let rawurl = `https://maps.googleapis.com/maps/api/staticmap?size=375x375&maptype=hybrid&markers=size:mid|color:orange|label:${lbl}|${this.geoPosition}&key=${key}`;

          this.openStaticMap(encodeURI(rawurl));

        }
        this.showMapFlag = true;
      }
    }
  }

  openServerReport(id: number): void {
    if (this.item != null && this.item.logIncidentId > 0) {

      let p: ReportParameter[];

      p = [
        { parameterName: "LogIncidentID", defaultValue: this.item.logIncidentId.toString() }
      ];

      this.dataService.initReportRequestWithParams(id, p)
        .subscribe(
        itms => {
          if (itms != null) {
            console.log(itms);
            let url = environment.reports + '/view/' + itms.id;
            window.open(url, '_blank');
          }
        },
        error => {
          console.log(error);
        });
    }
  }

  //getStaticMap(url): void {
  //  this.dataService.getStaticMap(url)
  //    .subscribe(
  //    itms => {
  //      if (itms != null && itms.length > 0) {
  //        this.staticMapTemplate = itms;
  //      }
  //    },
  //    error => {
  //      this.openSnackBar("error.message", "Error");
  //      this.staticMapTemplate = '';
  //      this.logger.error(error);
  //    });
  //}

  openStaticMap(url): void {
    window.open(url, '_blank');
  }

  goBack(): void {
    this.location.back();
  }
}
