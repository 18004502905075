import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { TokenStorage } from '../login/tokenstorage.service';
//import { AlertService } from '../components/alert.service';
import { LogNavBarComponent } from './log-navbar.component';
import { LogHeader } from '../models/logheader';
import { Incident } from '../models/incident';
import { ReportParameter } from '../models/reportparameter';
import { DataService } from '../data.service';
import { LogHeaderService } from './logheader.service';
//import { MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Lookup } from '../models/lookup';
import { DepartmentDialog } from '../components/department-dialog';
import { DataTransport } from '../datatransport.provider';
import { ChildMessageRenderer } from "../aggrid/child-message-renderer.component";
import { convertToParamMap } from '@angular/router/src/shared';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'incidentlog',
  templateUrl: 'incidentlog.component.html'
  //styleUrls: ['incidentlog.component.css']
})
export class IncidentLogComponent {
  title = '';
  loading = false;
  reportingDay = moment();
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  departmentList: Lookup[] = [];
  departmentFilter = '';
  items: Incident[] = [];
  columnDefs;
  context;
  frameworkComponents;
  private gridApi;
  rowSelection = 'single';
  selectedRows: any = null;
  showReports: boolean = false;
  innerWidth: any;

  constructor(
    private router: Router,
    private location: Location,
    private token: TokenStorage,
    private dataService: DataService,
    private logHeaderService: LogHeaderService,
    public dialog: MatDialog,
    private data: DataTransport,
    private zone: NgZone
  ) {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      childMessageRenderer: ChildMessageRenderer
    };

    this.columnDefs = [
      {
        headerName: 'No.', field: 'logIncidentId', minWidth: 55, maxWidth: 75,
        cellRenderer: (params) => {
          var link = document.createElement('a');
          link.href = '#';
          link.innerText = params.value;
          link.addEventListener('click', (e) => {
            e.preventDefault();
            this.tempNavigate('/log/incident/' + params.data.logIncidentId);
          });
          return link;
        }
      },
      { headerName: 'Time', field: 'incidentTime', minWidth: 70, maxWidth: 100 },
      { headerName: 'Category', field: 'incidentCategory', minWidth: 90, maxWidth: 125 },
      { headerName: 'Description', field: 'incidentDescription', minWidth: 200, width: 250, autoHeight: true, cellClass: "cell-wrap-text" },
      { headerName: 'Dept.', field: 'departmentCode', minWidth: 75, maxWidth: 75 },
      { headerName: 'Reported by', field: 'reportedBy', minWidth:125, maxWidth: 125 },
      {
        headerName: 'Reports', field: 'hasReport', minWidth: 90, maxWidth: 90,
        cellRenderer: (params) => {
          if (params.value || params.data.hasViolation) {
            return 'Yes';
          }
          return "";
        }
      }
    ];
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    let currentUser = this.token.getUser();
    if (currentUser.userLevel > 1)
      this.departmentFilter = currentUser.departmentId.toString();
    this.getLogHeader();
  }

  onDateChange(): void {
    //this.title = "Reporting day " + this.reportingDay.toLocaleDateString();
    console.log(this.reportingDay);
    this.logHeaderService.setReportingDay(this.reportingDay.toDate());
    this.getLogHeader();
  }

  getLogHeader(): void {
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
        this.reportingDay = moment(this.logHeader.reportingDay);
        this.getIncidents();
        this.getDepartmentList();
      },
      error => {
        console.log(error);
        this.router.navigate(['/log/header']);
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    setTimeout(function () {
      params.api.resetRowHeights();
    }, 300);
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  onSelectionChanged() {
    //console.log(event);
    this.selectedRows = this.gridApi.getSelectedRows();
  }

  methodFromParent(caller, params) {
    //alert("Parent Component Method from " + caller + " for value " + value);
    //this.router.navigate(['/scheduling/shiftplan/' + this.shiftPlanId.toString() + '/shift/' + params.value.toString()]);
    //this.openDialog(params.data);
  }

  getIncidents(): void {
    let deptId = 0;
    if (this.departmentFilter !== '') {
      deptId = Number(this.departmentFilter);
    }
    this.dataService.getIncidents(this.logHeader.logHeaderId, deptId)
      .subscribe(
      itms => {
        if (itms != null) this.items = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.items = [];
        console.log(error);
      });
  }

  getDepartmentList(): void {
    this.dataService.getDepartmentLookups()
      .subscribe(
      itms => {
        if (itms != null) {
          itms.unshift({ id: '', label: 'All departments' });
          this.departmentList = itms;
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.departmentList = [];
        console.log(error);
      });
  }

  openDeptDialog(): void {
    let dialogRef = this.dialog.open(DepartmentDialog, {
      width: '270px',
      data: { title: "Department filter", departmentList: this.departmentList, departmentId: this.departmentFilter }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result == undefined) {
        //result = '';
        this.departmentFilter = '';
      } else {
        this.departmentFilter = result;
      }
      //this.dataSource.filter = this.departmentFilter;
      //filter at db
      this.getIncidents();


    });
  }

  goToReport(): void {
    if (this.selectedRows != null && this.selectedRows.length > 0) {
      var url = '/log/incident/' + this.selectedRows[0].logIncidentId + '/report';
      this.data.storage = this.selectedRows[0];
      this.router.navigate([url]);
    }
  }

  openServerReport(id: number): void {
    if (this.items != null && this.items.length > 0) {

      let p: ReportParameter[];

      p = [
        { parameterName: "FromDate", defaultValue: moment(this.logHeader.reportingDay).format("YYYY-MM-DD") },
        { parameterName: "ToDate", defaultValue: moment(this.logHeader.reportingDay).format("YYYY-MM-DD") },
        { parameterName: "DepartmentId", defaultValue: this.departmentFilter }
      ];

      this.dataService.initReportRequestWithParams(id, p)
        .subscribe(
        itms => {
          if (itms != null) {
            console.log(itms);
            let url = environment.reports + '/view/' + itms.id;
            window.open(url, '_blank');
          }
        },
        error => {
          console.log(error);
        });
    }
  }

  goBack(): void {
    this.location.back();
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }
}
