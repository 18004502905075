import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'my-app',
    template: `
    <div class="main-container">
        <alert></alert>
        <router-outlet></router-outlet>
    </div>
`,
    //styleUrls: ['app.component.css'],
})
export class AppComponent  { title = 'Harbor Manager'; }
