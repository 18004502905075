import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ResponseContentType } from '@angular/http';
import { Observable, throwError } from 'rxjs';

import { environment } from '../environments/environment';



@Injectable()
export class ExternalService {


  private apiUrl = environment.api;

  constructor(
    private http: HttpClient,
  )
  {
  }


  public getTidesAndCurrents(fromDate: string, toDate: string): any {
    let url = `${this.apiUrl}/external/tides/${fromDate}/${toDate}`;
    return this.http.get<any>(url);
  }

  public getTodaysWeather(): any {
    let url = `${this.apiUrl}/external/weather/today`;
    return this.http.get<any>(url);
  }



}

