import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TokenStorage } from '../login/tokenstorage.service';
//import { AlertService } from '../components/alert.service';
import { Incident } from '../models/incident';
import { DataService } from '../data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Lookup } from '../models/lookup';
import { DepartmentDialog } from '../components/department-dialog';
import { DataTransport } from '../datatransport.provider';
import { convertToParamMap } from '@angular/router/src/shared';
import * as moment from 'moment';
import { IncidentSearch } from '../models/incidentsearch';
import { IncidentSearchFilter } from '../models/incidentsearchfilter';

@Component({
  moduleId: module.id,
  selector: 'incidentsearch',
  templateUrl: 'incidentsearch.component.html'
})
export class IncidentSearchComponent {
  title = '';
  loading = false;
  reportingDay = moment();
  departmentList: Lookup[] = [];
  departmentFilter = '';
  items: IncidentSearch[] = [];
  columnDefs;
  context;
  frameworkComponents;
  gridApi;
  rowSelection = 'single';
  selectedRows: any = null;
  searchFilter = new IncidentSearchFilter();
  deptDisplay = '';

  constructor(
    private router: Router,
    private location: Location,  
    private token: TokenStorage,
    private dataService: DataService,
    public dialog: MatDialog,
    private data: DataTransport,
    private zone: NgZone
  ) {
    this.context = { componentParent: this };
    //this.frameworkComponents = {
    //  childMessageRenderer: ChildMessageRenderer
    //};

    this.columnDefs = [
      {
        headerName: 'No.', field: 'logIncidentId', minWidth: 65, maxWidth: 75,
        cellRenderer: (params) => {
          var link = document.createElement('a');
          link.href = '#';
          link.innerText = params.value;
          link.addEventListener('click', (e) => {
            e.preventDefault();
            if(params.data.tableName == 'Incident')
              this.tempNavigate('/log/incident/' + params.data.logIncidentId);
            else
              this.tempNavigate('/log/incident/' + params.data.logIncidentId + '/report');
          });
          return link;
        }
      },
      {
        headerName: 'Day', field: 'reportingDay', minWidth: 90, maxWidth: 125,
        cellRenderer: (params) => {
          if (params.value) {
            return moment(params.value).format("MM/DD/YYYY");
          }
          return "";
        }
      },
      { headerName: 'Category', field: 'incidentCategory', minWidth: 90, maxWidth: 125 },
      { headerName: 'Table', field: 'tableName', minWidth: 75, maxWidth: 100 },
      { headerName: 'Key words', field: 'description', minWidth: 200, width: 250, autoHeight: true, cellClass: "cell-wrap-text" },
      { headerName: 'Dept.', field: 'departmentCode', minWidth: 75, maxWidth: 75 },
      { headerName: 'Reported by', field: 'reportedBy', minWidth:125, maxWidth: 125 }
    ];

    this.searchFilter.fromDate = moment().add(-1, 'year').toDate();
    this.searchFilter.toDate = moment().toDate();
    this.searchFilter.searchFor = "";
  }

  ngOnInit(): void {
    let currentUser = this.token.getUser();
    if (currentUser.userLevel > 1)
      this.departmentFilter = currentUser.departmentId.toString();
    this.getDepartmentList();
    this.searchFilter.searchIncidents = true;
    this.searchFilter.searchReports = false;
    this.searchFilter.searchViolations = false;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    setTimeout(function () {
      params.api.resetRowHeights();
    }, 300);
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  onSelectionChanged() {
    //console.log(event);
    this.selectedRows = this.gridApi.getSelectedRows();
  }

  getIncidentSearch(): void {
    let deptId = 0;
    if (this.departmentFilter !== '') {
      deptId = Number(this.departmentFilter);
    }

    //this.searchFilter.fromDate = new Date(2000, 1, 1);
    //this.searchFilter.toDate = new Date(2020, 1, 1);
    this.searchFilter.departmentId = deptId;
    //this.searchFilter.searchFor = "jones";

    if (this.searchFilter.searchReports == false && this.searchFilter.searchViolations == false) {
      this.searchFilter.searchIncidents = true;
    }

    this.dataService.getIncidentSearch(this.searchFilter)
      .subscribe(
      itms => {
        if (itms != null) this.items = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.items = [];
        console.log(error);
      });
  }

  getDepartmentList(): void {
    this.dataService.getDepartmentLookups()
      .subscribe(
      itms => {
        if (itms != null) {
          itms.unshift({ id: '', label: 'All departments' });
          this.departmentList = itms;
          this.setDeptDisplay();
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.departmentList = [];
        console.log(error);
      });
  }

  openDeptDialog(): void {
    let dialogRef = this.dialog.open(DepartmentDialog, {
      width: '270px',
      data: { title: "Department filter", departmentList: this.departmentList, departmentId: this.departmentFilter }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result == undefined) {
        //result = '';
        this.departmentFilter = '';
      } else {
        this.departmentFilter = result;
      }
      this.setDeptDisplay();
      //this.dataSource.filter = this.departmentFilter;
      //filter at db
      //this.getIncidents();


    });
  }

  setDeptDisplay(): void {
    this.deptDisplay = this.departmentList.find(x => x.id == this.departmentFilter).label;
  }

  goToReport(): void {
    if (this.selectedRows != null && this.selectedRows.length > 0) {
      var url = '/log/incident/' + this.selectedRows[0].logIncidentId + '/report';
      this.data.storage = this.selectedRows[0];
      this.router.navigate([url]);
    }
  }

  goBack(): void {
    this.location.back();
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }
}
