import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { TokenStorage } from '../login/tokenstorage.service';
import { LogNavBarComponent } from './log-navbar.component';
import { UserProfile } from '../models/userprofile';
import { LogHeader } from '../models/logheader';
import { OnDuty } from '../models/onduty';
import { PatrolBoatInspection } from '../models/patrolboatinspection';
import { AtDock } from '../models/atdock';
import { DataService } from '../data.service';
import { LogHeaderService } from './logheader.service';
import { MatTableDataSource } from '@angular/material';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'inspections',
  templateUrl: 'inspections.component.html'
})
export class InspectionsComponent {
  currentUser: UserProfile; 
  title = '';
  reportingDay = moment();
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  //onDutyList: OnDuty[] = [];
  patrolBoatList: PatrolBoatInspection[] = [];
  atDockList: AtDock[] = [];
  harborVisible: boolean = true;

  constructor(
    private router: Router,
    private location: Location,   
    private token: TokenStorage,
    private dataService: DataService,
    private logHeaderService: LogHeaderService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    if (this.currentUser.userLevel > 1 && this.currentUser.departmentCode != 'HM') {
      this.harborVisible = false;
    }
    this.getLogHeader();
  }

  onDateChange(): void {
    //this.title = "Reporting day " + this.reportingDay.toLocaleDateString();
    console.log(this.reportingDay);
    this.logHeaderService.setReportingDay(this.reportingDay.toDate());
    this.getLogHeader();
  }

  getLogHeader(): void {
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
        this.reportingDay = moment(this.logHeader.reportingDay);
        //this.getOnDuty();
        this.getPatrolBoats();
        this.getAtDock();
      },
      error => {
        console.log(error);
        this.router.navigate(['/log/header']);
      });
  }

  //getOnDuty(): void {
  //  this.dataService.getOnDuty(this.logHeader.logHeaderId)
  //    .subscribe(
  //    itms => {
  //      if (itms != null) this.onDutyList = itms;
  //    },
  //    error => {
  //      this.openSnackBar("error.message", "Error");
  //      this.onDutyList = [];
  //      console.log(error);
  //    });

  //}

  getPatrolBoats(): void {
    this.dataService.getPatrolBoatInspections(this.logHeader.logHeaderId)
      .subscribe(
      itms => {
        if (itms != null) this.patrolBoatList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.patrolBoatList = [];
        console.log(error);
      });
  }

  getAtDock(): void {
    this.dataService.getAtDock(this.logHeader.logHeaderId)
      .subscribe(
      itms => {
        if (itms != null) this.atDockList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.atDockList = [];
        console.log(error);
      });
  }

  goBack(): void {
    this.location.back();
  }

}
