import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

//#17a2b8 = text-info

@Component({
  selector: 'child-cell',
  template: `<span>
    <button type="button" class="btn btn-link" (click)="returnToParent('owner')" title="Owner"><span class="fas fa-user"></span></button>
    <button type="button" class="btn btn-link" (click)="returnToParent('boat')" title="Vessel"><span class="fas fa-ship"></span></button>
    <button type="button" class="btn btn-link" (click)="returnToParent('lease')" title="Lease"><span class="fas fa-anchor"></span></button>
    </span>`,
  styles: [
    `.btn {
            line-height: 0.5
        }
    .btn-link {
      color: #17a2b8;
    }`
  ]
})
export class MooringActionRenderer implements ICellRendererAngularComp {
  public params: any;
  
  //isVisible = {
  //  edit: true,
  //  schedule: false
  //};

  agInit(params: any): void {
    this.params = params;
    //if (this.params.context.componentParent.constructor.name == "HouseStaffListComponent") {
    //  this.isVisible.schedule = true;
    //}
  }

  public returnToParent(caller) {
    this.params.context.componentParent.methodFromParent(caller, this.params)
  }

  refresh(): boolean {
    return false;
  }
}
