import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { LogNavBarComponent } from './log-navbar.component';
import { AnimalsInShelter } from '../models/animalsinshelter';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'animalsinshelterlog',
  templateUrl: 'animalsinshelterlog.component.html'
})
export class AnimalsInShelterLogComponent {
  title = '';
  loading = false;
  items: AnimalsInShelter[] = [];
  selectedFilterFlag: boolean = true;
  selectedSpecies = '';
  filterOn = false;
  filterOpen = false;
  fromDate= null; // moment().add(-1, 'month');
  toDate = null; //moment();
  speciesList: Lookup[] = [];

  private columnDefs;
  private context;
  private frameworkComponents;
  private gridApi;

  constructor(
    private router: Router,
    private dataService: DataService,
    private zone: NgZone
  ) {
    this.context = { componentParent: this };
    //this.frameworkComponents = {
    //  childMessageRenderer: ChildMessageRenderer
    //};

    this.columnDefs = [
      {
        headerName: 'ID', field: 'animalsInShelterId', minWidth: 50, maxWidth: 125,
        cellRenderer: (params) => {
          var link = document.createElement('a');
          link.href = '#';
          link.innerText = params.value;
          link.addEventListener('click', (e) => {
            e.preventDefault();
            //console.log(params.data.mooringNumber);
            this.tempNavigate('/log/inshelter/' + params.data.animalsInShelterId);
          });
          return link;
        }
      },
      { headerName: 'Species', field: 'species', minWidth: 100, maxWidth: 200 },
      { headerName: 'Name', field: 'animalName', minWidth: 100, maxWidth: 200 },
      {
        headerName: 'Rec\'d', field: 'dateReceived', minWidth: 75, maxWidth: 125,
        cellRenderer: (params) => {
          if (params.value) {
            return moment(params.value).format("MM/DD/YYYY");
          }
          return "";
        }
      },
      {
        headerName: 'Dep\'d.', field: 'dateDeparted', minWidth: 75, maxWidth: 125,
        cellRenderer: (params) => {
          if (params.value) {
            return moment(params.value).format("MM/DD/YYYY");
          }
          return "";
        }
      }
      //{
      //  headerName: "",
      //  field: "shiftPlanDetailId",
      //  cellRenderer: "childMessageRenderer",
      //  colId: "params",
      //  width: 150
      //}
    ];

  }

  ngOnInit(): void {
    this.getSpeciesList();
    this.getAnimalsInShelter();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }


  methodFromParent(caller, params) {
    //alert("Parent Component Method from " + caller + " for value " + value);
    //this.router.navigate(['/scheduling/shiftplan/' + this.shiftPlanId.toString() + '/shift/' + params.value.toString()]);
    //this.openDialog(params.data);
  }

  getSpeciesList(): void {
    this.dataService.getListReferenceLookups('animalspecies')
      .subscribe(
      itms => {
        if (itms != null) this.speciesList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.speciesList = [];
        console.log(error);
      });
  }

  getAnimalsInShelter(): void {
    var defaultFilter = false;

    if (this.selectedSpecies == '' &&
      this.selectedFilterFlag == true &&
      this.fromDate == null &&
      this.toDate == null) {
      defaultFilter = true;
    }
    if (defaultFilter) {
      this.dataService.getAnimalsInShelterDefault()
        .subscribe(
        itms => {
          if (itms != null) this.items = itms;
          this.filterOn = !defaultFilter;
        },
        error => {
          this.dataService.openSnackBar("error.message", "Error");
          this.items = [];
          console.log(error);
        });
    } else {
      var from = '';
      var to = '';
      if (this.fromDate != null)
        from = moment(this.fromDate).format("YYYY-MM-DD");
      if (this.toDate != null)
        to = moment(this.toDate).format("YYYY-MM-DD");

      this.dataService.getAnimalsInShelter(this.selectedFilterFlag, from, to, this.selectedSpecies)
        .subscribe(
        itms => {
          if (itms != null) this.items = itms;
          this.filterOn = !defaultFilter;
        },
        error => {
          this.dataService.openSnackBar("error.message", "Error");
          this.items = [];
          console.log(error);
        });
    }
  }

  openFilter(): void {
    this.filterOn = !this.filterOn;
  }

  clearForm(): void {
    this.selectedFilterFlag = true;
    this.selectedSpecies = '';
    //filterOn = false;
    this.fromDate = null;
    this.toDate = null;
    this.filterOn = false;
    this.getAnimalsInShelter();
  }

  filterChanged(): void {
    this.getAnimalsInShelter();
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  //openInShelterDialog(): void {
  //  let dialogRef = this.dialog.open(DepartmentDialog, {
  //    width: '270px',
  //    data: { title: "Department filter", departmentList: this.departmentList, departmentId: this.selectedFilter }
  //  });

  //  dialogRef.afterClosed().subscribe(result => {
  //    console.log('The dialog was closed');
  //    if (result == undefined) {
  //      //result = '';
  //    } else {
  //      this.selectedFilter = result;
  //    }
  //  });
  //}

}
