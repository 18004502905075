import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TokenStorage } from '../login/tokenstorage.service';
import { MooringLease } from '../models/mooringlease';
import { LeaseType } from '../models/leasetype';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';
import { MatSnackBar } from '@angular/material';
import { DataTransport } from '../datatransport.provider';
import * as moment from 'moment';
import { ConsoleLoggerService } from '../components/console-logger.service';
import { environment } from '../../environments/environment';
//
import { MooringTreeService } from "../components/mooringtree.service";
import { MooringTreeSelected } from '../models/mooringtreeselected';
import { Subscription } from 'rxjs/Subscription';

@Component({
  moduleId: module.id,
  selector: 'app-mooringleaseform',
  templateUrl: 'mooringlease.component.html',
})
export class MooringLeaseComponent {
  title = '';
  item: MooringLease = null;
  personId: number = 0
  boatId: number = 0;
  leaseId: number = 0;
  leaseTypes: LeaseType[] = [];
  leaseYearList: number[] = [];
  locationCodeList: Lookup[] = [];
  lastChangedDateString = '';
  leasePeriodString = '';
  geoPositionLabel = '';
  geoPosition = '';
  showMapFlag = false;
  staticMapTemplate: any = '';
  private nodeSelectedSubscription: Subscription;
  @Input() IsChild: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private token: TokenStorage,
    private snackBar: MatSnackBar,
    private router: Router,
    private dataService: DataService,
    private treeService: MooringTreeService,
    private location: Location,
    private data: DataTransport,
    private logger: ConsoleLoggerService
  ) {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id && id > 0) {
      this.personId = id;
      this.boatId = +this.route.snapshot.paramMap.get('boatId');
      this.leaseId = +this.route.snapshot.paramMap.get('leaseId');
    }
  }

  ngOnInit(): void {
    this.nodeSelectedSubscription = this.treeService.observableSelected
      .subscribe(item => {
        this.nodeSelectedEvent(item as MooringTreeSelected);
      });

    if (this.data.storage != undefined && this.data.storage.title != null) {
      this.title = "Lease - " + this.data.storage.title;
    } else {
      this.title = "Lease";
    }

    let thisYear = new Date().getFullYear();
    this.leaseYearList = [thisYear, thisYear - 1];

    this.item = new MooringLease();
    this.getLeaseTypes();
    this.getLocationCodeList();
    this.getMooringLease();

  }

  ngOnDestroy() {
    // Unsubscribe when the component is destroyed
    this.nodeSelectedSubscription.unsubscribe();
  }

  nodeSelectedEvent(node: MooringTreeSelected): void {
    if (node == null)
      return;
    if (node.personId > 0 && node.leaseId > 0) {
      this.personId = node.personId;
      this.boatId = node.boatId;
      this.leaseId = node.leaseId;
      this.getMooringLease();
    }
  }

  getLeaseTypes(): void {
    this.dataService.getLeaseTypes(false)
      .subscribe(
      itms => {
        if (itms != null) this.leaseTypes = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.leaseTypes = [];
        console.log(error);
      });
  }

  getLocationCodeList(): void {
    this.dataService.getListReferenceLookups('locationcode')
      .subscribe(
      itms => {
        if (itms != null) this.locationCodeList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.locationCodeList = [];
        console.log(error);
      });
  }

  getMooringLease(): void {
    if (this.leaseId === 0)
      return;
    this.dataService.getMooringLeaseById(this.personId, this.boatId, this.leaseId)
      .subscribe(
      itm => {
        if (itm != null) {
          this.item = itm;
          if (this.item.latitude == null || this.item.longitude == null) {
            this.geoPositionLabel = "Geolocation not set";
            this.geoPosition = '';
          } else {
            this.geoPositionLabel = this.item.latitude + "," + this.item.longitude;
            this.geoPosition = this.item.latitude + "," + this.item.longitude;
          }
          this.refreshLeasePeriod();
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  saveForm(): void {
    this.item.fk_BoatId = this.boatId;
    var item = this.dataService.saveMooringLease(this.personId, this.boatId, this.item as MooringLease)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        //this.goBack();
        this.openSnackBar("Update succesful", "Lease");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  setGeoLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {

        this.item.latitude = position.coords.latitude;
        this.item.longitude = position.coords.longitude;

        this.geoPositionLabel = this.item.latitude + "," + this.item.longitude;
        this.geoPosition = this.item.latitude + "," + this.item.longitude;

      });
    } else {
      this.item.latitude = null;
      this.item.longitude = null;

      this.geoPositionLabel = "Geolocation is not supported by this browser.";
      this.geoPosition = '';
    }
    this.logger.log(this.geoPositionLabel);
  }

  refreshLeasePeriod(): void {

    if (this.item.leaseStart != null) {
      this.leasePeriodString = moment(this.item.leaseStart).format('YYYY-MM-DD');
      if (this.item.leaseEnd != null) {
        this.leasePeriodString = this.leasePeriodString + ' to ' + moment(this.item.leaseEnd).format('YYYY-MM-DD');
      }
    }
    //if (this.item.leaseYear > 1980) {
    //  let dt = new Date(this.item.leaseYear, 0, 1);
    //  this.leasePeriodString = moment(dt).format('YYYY-MM-DD') + ' to ' + moment(dt).add(1, 'y').format('YYYY-MM-DD');
    //} else {
    //  this.leasePeriodString = '';
    //}
  }

  toggleMap(): void {
    if (this.showMapFlag == true) {
      this.showMapFlag = false;
    } else {
      if (this.geoPosition != '') {
        if (this.staticMapTemplate == '') {
          let lbl = this.token.getUser().departmentCode.substr(0, 1);
          let key = environment.mapApi.key;
          let rawurl = `https://maps.googleapis.com/maps/api/staticmap?size=375x375&maptype=hybrid&markers=size:mid|color:orange|label:${lbl}|${this.geoPosition}&key=${key}`;

          this.openStaticMap(encodeURI(rawurl));

        }
        this.showMapFlag = true;
      }
    }
  }

  openStaticMap(url): void {
    window.open(url, '_blank');
  }


  nextMooringNumber(): void {
    this.openSnackBar("Feature not enabled", "Mooring Number");
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  goBack(): void {
    this.location.back();
  }
}
