import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
//import { AlertService } from '../components/alert.service';
import { LogNavBarComponent } from './log-navbar.component';
import { LogHeader } from '../models/logheader';
import { OnDuty } from '../models/onduty';
import { DataService } from '../data.service';
import { LogHeaderService } from './logheader.service';
import { Lookup } from '../models/lookup';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'ondutylog-form',
  templateUrl: 'ondutylog.component.html'
})
export class OnDutyLogComponent {
  title = '';
  reportingDay = moment();
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  item: OnDuty = null;
  employeeList: Lookup[] = [];
  onDutyList: OnDuty[] = [];
  columnDefs;
  context;
  //frameworkComponents;
  gridApi;
  rowSelection = 'single';
  selectedRows: any = null;

  constructor(
    // private route: ActivatedRoute,
    private router: Router,
    private location: Location,  
    private dataService: DataService,
    private logHeaderService: LogHeaderService
  ) {
    this.context = { componentParent: this };
    //this.frameworkComponents = {
    //  childMessageRenderer: ChildMessageRenderer
    //};

    this.columnDefs = [
      { headerName: 'Employee', field: 'knownAs', minWidth: 100, maxWidth: 175 },
      { headerName: 'Dept.', field: 'departmentCode', minWidth: 50, maxWidth: 75 },
      {
        headerName: 'Time', field: 'startTime', minWidth: 75, maxWidth: 100,
        cellRenderer: (params) => {
          if (params.value) {
            if (params.data.endTime) {
              return params.value + '-' + params.data.endTime;
            } else {
              return params.value;
            }
          }
          return "";
        }
      },
      { headerName: 'Notes', field: 'notes', minWidth: 100, width: 200, autoHeight: true, cellClass: "cell-wrap-text" }
    ];
  }

  ngOnInit(): void {
    this.item = new OnDuty();
    this.getEmployeeList();
    this.getLogHeader();
  }

  onDateChange(): void {
    //this.title = "Reporting day " + this.reportingDay.toLocaleDateString();
    console.log(this.reportingDay);
    this.clearForm();
    this.logHeaderService.setReportingDay(this.reportingDay.toDate());
    this.getLogHeader();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;

    //params.api.sizeColumnsToFit();
    //setTimeout(function () {
    //  params.api.resetRowHeights();
    //}, 300);
  }

  onSelectionChanged(e) {
    //console.log(event);
    this.selectedRows = this.gridApi.getSelectedRows();
    if (this.selectedRows != null && this.selectedRows.length > 0) {
      this.item = this.selectedRows[0];
    }
  }

  getLogHeader(): void {
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
        this.getOnDuty();
      },
      error => {
        console.log(error);
        this.router.navigate(['/log/header']);
      });
  }

  getEmployeeList(): void {
    this.dataService.getEmployeeLookups()
      .subscribe(
      itms => {
        if (itms != null) this.employeeList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.employeeList = [];
        console.log(error);
      });
  }

  getOnDuty(): void {
    this.dataService.getOnDuty(this.logHeader.logHeaderId)
      .subscribe(
      itms => {
        if (itms != null) this.onDutyList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.onDutyList = [];
        console.log(error);
      });

  }

  saveForm(): void {

    var flag: boolean;
    this.item.fk_LogHeaderId = this.logHeader.logHeaderId;
    var item = this.dataService.saveOnDuty(this.item as OnDuty)
      .subscribe(result => {
        // Handle result
        console.log(result);
        this.clearForm();
        this.getOnDuty();
        //this.goBack();
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
    //this.statusLabel = 'saved';
  }

  clearForm(): void {
    this.item = new OnDuty();
    this.gridApi.deselectAll();
  }

  setTime(fld): void {
    if (fld == 'startTime') {
      this.item.startTime = moment(Date.now()).format("HH:mm");
    } else {
      this.item.endTime = moment(Date.now()).format("HH:mm");
    }
  }

  goBack(): void {
    this.location.back();
  }
}
