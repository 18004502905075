import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { UserProfile } from '../models/userprofile';
import { UserLoginContainer } from '../models/userlogincontainer';
//import { AlertService } from '../components/alert.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    public token: string;
    //private apiUrl = 'https://www.harborplus.com/api'; 
  //private apiUrl = 'http://localhost:8738/api';
  private apiUrl = environment.api;

    constructor(
      private http: HttpClient
    ) { }

    login(userName: string, password: string): Observable<any> {
      let url = `${this.apiUrl}/token`;
      let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      let body = JSON.stringify({ userName: userName, password: password });

      return this.http.post<UserLoginContainer>(url, body, httpOptions);
        //.pipe(
        ////tap(log => this.logHeaderService.setLogHeader(log)),
        //catchError(this.handleError<UserLoginContainer>('login'))
        //);
    }

    //logout(): void {
    //    // clear token remove user from local storage to log user out
    //    this.token = null;
    //    localStorage.removeItem('currentUser');
    //}

}
