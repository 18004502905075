export class LogHeader {
  logHeaderId: number;
  reportingDay: Date;
  sunrise: string;
  sunset: string;
  aM_LowTide: string;
  pM_LowTide: string;
  aM_HighTide: string;
  pM_HighTide: string;
  marineForecast: string;
  landForecast: string;
}
