//import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
//import './rxjs-extensions';
import { MomentModule } from 'ngx-moment';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { Ng2ImgMaxModule } from 'ng2-img-max';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatToolbarModule,
  MatTooltipModule,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material';

import { AgGridModule } from 'ag-grid-angular';

//services
import { DataService } from './data.service';
import { DataTransport } from './datatransport.provider';
import { ExternalService } from './external.service';
import { FileUploadService } from './fileupload.service';
import { AlertService } from './components/alert.service';
import { AuthenticationService } from "./login/authentication.service";
import { Interceptor } from "./login/interceptor";
import { TokenStorage } from "./login/tokenstorage.service";
import { AuthGuard } from './auth.guard';
import { ConsoleLoggerService } from './components/console-logger.service';

//modules
import { LogModule } from './logs/log.module';
import { MooringModule } from './moorings/mooring.module';
import { ConfirmDialogModule } from './components/confirm-dialog.module';
import { MooringTreeModule } from "./components/mooringtree.module";  

//components
import { MainNavBarComponent } from './dashboard/main-navbar.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './components/not-found.component';

import { AlertComponent } from './components/alert.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './login/changepassword.component';
import { DepartmentDialog } from './components/department-dialog';
import { DashboardComponent } from './dashboard/dashboardcomponent';
import { NoteComponent } from './components/note.component';
import { FileComponent } from './components/file.component';
import { ListReferenceComponent } from './settings/listreference.component';

import { IncidentStatsComponent } from './reports/incidentstats.component';
import { IncidentSearchComponent } from './reports/incidentsearch.component';

import { UserLogComponent } from './user/userlog.component';
import { UserProfileComponent } from './user/userprofile.component';

//aggrid
import { ChildMessageRenderer } from "./aggrid/child-message-renderer.component";
import { MooringActionRenderer } from "./aggrid/mooring-action-renderer.component";

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatToolbarModule,
    MatTooltipModule,
  ]
})
export class MyMaterialModule { }

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MyMaterialModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    MomentModule,
    Ng2ImgMaxModule,
    AgGridModule.withComponents([ChildMessageRenderer, MooringActionRenderer]),
    //NgbModule,
    LogModule,
    MooringModule,
    AppRoutingModule,
    ConfirmDialogModule,
    MooringTreeModule
  ],
  entryComponents: [
    DepartmentDialog
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    MainNavBarComponent,
    AlertComponent,
    LoginComponent,
    ChangePasswordComponent,
    //DepartmentFilterPipe,
    DashboardComponent,
    DepartmentDialog,
    NoteComponent,
    FileComponent,
    ListReferenceComponent,
    ChildMessageRenderer,
    MooringActionRenderer,
    IncidentStatsComponent,
    IncidentSearchComponent,
    UserLogComponent,
    UserProfileComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    AuthGuard,
    ConsoleLoggerService,
    AlertService,
    AuthenticationService,
    TokenStorage,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    DataService,
    DataTransport,
    FileUploadService,
    ExternalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
