import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ResponseContentType } from '@angular/http';
//import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
//import { catchError, retry } from 'rxjs/operators';

//import { SomeClassOrInterface } from './interfaces';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
//import { ListValue } from './component/listvalue';

//
import { environment } from '../environments/environment';
import { Lookup } from './models/lookup';
import { LookupWithParent } from './models/lookupwithparent';
import { LogHeader } from './models/logheader';
import { OnSceneWeather } from './models/onsceneweather';
import { Incident } from './models/incident';
import { IncidentReport } from './models/incidentreport';
import { IncidentViolation } from './models/incidentviolation';
import { OnDuty } from './models/onduty';
import { PatrolBoatInspection } from './models/patrolboatinspection';
import { AtDock } from './models/atdock';
import { AnimalsInShelter } from './models/animalsinshelter';
//import { LogHeaderService } from './logs/logheader.service';
import { Note } from './models/note';
import { FileListModel } from './models/filelistmodel';
import { ListReferenceGroup } from './models/listreferencegroup';
import { ListReference } from './models/listreference';
//
import { LeaseType } from './models/leasetype';
import { FeeType } from './models/feetype';
import { MooringList } from './models/mooringlist';
import { MooringView } from './models/mooringview';
import { MooringListContainer } from './models/mooringlistcontainer';
import { Person } from './models/person';
import { Address } from './models/address';
import { Boat } from './models/boat';
import { Fee } from './models/fee';
import { LeaseAccountModel } from './models/leaseaccountmodel';

//
import { MooringApplicant } from './models/mooringapplicant';
//
import { ReportRequest } from './models/reportrequest';
import { UserProfile } from 'src/app/models/userprofile';
import { UserPasswordChange } from './models/userpasswordchange';
import { IncidentSearchFilter } from 'src/app/models/incidentsearchfilter';
import { IncidentSearch } from 'src/app/models/incidentsearch';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';
import { MooringLease } from 'src/app/models/mooringlease';


@Injectable()
export class DataService {

  //private apiUrl = 'https://www.harborplus.com/api'; 
  //private apiUrl = 'http://localhost:8738/api';
  private apiUrl = environment.api;
  public mooringListContainer: MooringListContainer; 

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
    //private logHeaderService: LogHeaderService
  )
  {
    this.mooringListContainer = new MooringListContainer();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  //*** LISTS ***//

  getListReferenceLookups(groupName: string): Observable<Lookup[]> {
    let url = `${this.apiUrl}/data/list/${groupName}`;
    return this.http.get<Lookup[]>(url);
    //return this.http.get<Lookup[]>(url).pipe(
    //  tap(e => console.log('here!')),
    //  catchError(this.handleError)
    //);
  }

  getListIncidentCategoriesLookups(id): Observable<LookupWithParent[]> {
    if (id == null) { id = 0;}
    let url = `${this.apiUrl}/data/incidentcategories/${id}`;
    return this.http.get<LookupWithParent[]>(url);
    //return this.http.get<LookupWithParent[]>(url).pipe(
    //  tap(e => console.log('here!')),
    //  catchError(this.handleError)
    //);
  }

  getListIncidentSubCategoriesLookups(id): Observable<LookupWithParent[]> {
    if (id == null) { id = 0; }
    let url = `${this.apiUrl}/data/incidentsubcategories/${id}`;
    return this.http.get<LookupWithParent[]>(url);
    //return this.http.get<LookupWithParent[]>(url).pipe(
    //  tap(e => console.log('here!')),
    //  catchError(this.handleError)
    //);
  }

  getDepartmentLookups(): Observable<Lookup[]> {
    let url = `${this.apiUrl}/data/departments`;
    return this.http.get<Lookup[]>(url);
  }

  getUserLookups(): Observable<Lookup[]> {
    let url = `${this.apiUrl}/data/users`;
    return this.http.get<Lookup[]>(url);
  }

  getEmployeeLookups(): Observable<Lookup[]> {
    let url = `${this.apiUrl}/data/employees`;
    return this.http.get<Lookup[]>(url);
  }

  getPatrolBoatLookups(): Observable<Lookup[]> {
    let url = `${this.apiUrl}/data/patrolboats`;
    return this.http.get<Lookup[]>(url);
  }

  getBoatsAtDockLookups(): Observable<Lookup[]> {
    let url = `${this.apiUrl}/data/atdockboats`;
    return this.http.get<Lookup[]>(url);
  }

  getListReferenceGroups(): Observable<ListReferenceGroup[]> {
    let url = `${this.apiUrl}/data/list/groups`;
    return this.http.get<ListReferenceGroup[]>(url);
  }

  getListReferencesById(id:number): Observable<ListReference[]> {
    let url = `${this.apiUrl}/data/list/groups/${id}/items`;
    return this.http.get<ListReference[]>(url);
  }

  saveListReference(body: ListReference): Observable<ListReference> {
    let url = `${this.apiUrl}/data/list/groups/items`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<ListReference>(url, body, httpOptions);
  }

  

  //*** END LISTS ***//


  //*** FIND ***//



  //*** End Find ***//

  findMooringLeaseId(boatRegistration: string, mooringNumber: number): Observable<number> {
    if (mooringNumber == undefined)
      mooringNumber = 0;
    if (boatRegistration == undefined)
      boatRegistration = '';

    let url = `${this.apiUrl}/mooring/find?boatRegistration=${boatRegistration}&mooringNumber=${mooringNumber}`;
    return this.http.get<number>(url);
  }

  //*** Department Logs ***//

  getLogHeader(dt: Date): Observable<LogHeader> {
    let dtfmt = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    let url = `${this.apiUrl}/logheader` + '/' + dtfmt;
    //let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.get<LogHeader>(url);
  }

  saveLogHeader(body: LogHeader): Observable<LogHeader> {
    let url = `${this.apiUrl}/logheader`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<LogHeader>(url, body, httpOptions);
  }

  getOnSceneWeather(id: number): Observable<OnSceneWeather> {
    let url = `${this.apiUrl}/logheader/logweather` + '/' + id;

    return this.http.get<OnSceneWeather>(url);

  }

  getOnSceneWeatherByFk(id: number): Observable<OnSceneWeather[]> {
    let url = `${this.apiUrl}/logheader/logweather` + '/' + id;

    return this.http.get<OnSceneWeather[]>(url);
  }

  saveOnSceneWeather(body: OnSceneWeather): Observable<OnSceneWeather> {
    let url = `${this.apiUrl}/logheader/logweather`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<OnSceneWeather>(url, body, httpOptions);
  }

  saveIncident(body: Incident): Observable<Incident> {
    let url = `${this.apiUrl}/logheader/incident`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<Incident>(url, body, httpOptions);
  }

  getIncidentById(id: number): Observable<Incident> {
    let url = `${this.apiUrl}/logheader/incident/${id}`;
    return this.http.get<Incident>(url);
  }

  getIncidents(id: number, departmentId:number): Observable<Incident[]> {
    let url = `${this.apiUrl}/logheader/incidents` + '/' + id;
    if (departmentId > 0) {
      url = url + '?departmentId=' + departmentId;
    }
    return this.http.get<Incident[]>(url);
  }

  getAtDock(id: number): Observable<AtDock[]> {
    let url = `${this.apiUrl}/logheader/atdock` + '/' + id;
    return this.http.get<AtDock[]>(url);
  }

  saveAtDock(body: AtDock): Observable<AtDock> {
    let url = `${this.apiUrl}/logheader/atdock`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<AtDock>(url, body, httpOptions);
  }

  getPatrolBoatInspections(id: number): Observable<PatrolBoatInspection[]> {
    let url = `${this.apiUrl}/logheader/patrolboat` + '/' + id;
    return this.http.get<PatrolBoatInspection[]>(url);
  }

  savePatrolBoatInspection(body: PatrolBoatInspection): Observable<PatrolBoatInspection> {
    let url = `${this.apiUrl}/logheader/patrolboat`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<PatrolBoatInspection>(url, body, httpOptions);
  }

  getOnDuty(id: number): Observable<OnDuty[]> {
    let url = `${this.apiUrl}/logheader/onduty` + '/' + id;
    return this.http.get<OnDuty[]>(url);
  }

  saveOnDuty(body: OnDuty): Observable<OnDuty> {
    let url = `${this.apiUrl}/logheader/onduty`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<OnDuty>(url, body, httpOptions);

  }

  getAnimalsInShelterDefault(): Observable<AnimalsInShelter[]> {
    let url = `${this.apiUrl}/inshelterlog/filter/default`;

    return this.http.get<AnimalsInShelter[]>(url);
  }

  getAnimalsInShelter(inShelter: boolean, fromDate: string, toDate: string, species: string): Observable<AnimalsInShelter[]> {
    let url = '';
    url = `${this.apiUrl}/inshelterlog/filter?IncludeInShelter=${inShelter}&FromDate=${fromDate}&ToDate=${toDate}&Species=${species}`;
    return this.http.get<AnimalsInShelter[]>(url);
  }

  saveAnimalsInShelter(body: AnimalsInShelter): Observable<AnimalsInShelter> {
    let url = `${this.apiUrl}/inshelter`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<AnimalsInShelter>(url, body, httpOptions);
  }

  //as in agular tutorial, use array to avoid 404
  getAnimalsInShelterById(id: number): Observable<AnimalsInShelter> {
    let url = `${this.apiUrl}/inshelter` + '/' + id;
    return this.http.get<AnimalsInShelter>(url);
  }

  saveIncidentReport(body: IncidentReport): Observable<IncidentReport> {
    let url = `${this.apiUrl}/logheader/incident/report`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<IncidentReport>(url, body, httpOptions);
  }

  getIncidentReports(id: number): Observable<IncidentReport[]> {
    let url = `${this.apiUrl}/logheader/incidents` + '/' + id + '/report';

    return this.http.get<IncidentReport[]>(url);
  }

  getIncidentReportById(reportId: number): Observable<IncidentReport> {
    let url = `${this.apiUrl}/logheader/incidents/report/${reportId}`;
    return this.http.get<IncidentReport>(url);
  }

  saveIncidentViolation(body: IncidentViolation): Observable<IncidentViolation> {
    let url = `${this.apiUrl}/logheader/incident/violation`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<IncidentViolation>(url, body, httpOptions);
  }

  getIncidentViolations(id: number): Observable<IncidentViolation[]> {
    let url = `${this.apiUrl}/logheader/incidents` + '/' + id + '/violation';

    return this.http.get<IncidentViolation[]>(url);
  }

  //*** END Department Logs ***//

  //*** Mooring ***//

  getLeaseTypes(includeFees:boolean): Observable<LeaseType[]> {
    let url = `${this.apiUrl}/mooring/leasetype/list/${includeFees}`;
    return this.http.get<LeaseType[]>(url);
  }

  getFeeTypes(activeOnly: boolean): Observable<FeeType[]> {
    let url = `${this.apiUrl}/mooring/feetype/list/${activeOnly}`;
    return this.http.get<FeeType[]>(url);
  }

  getRuleFeeDescription(feeTypeId: number): Observable<string> {
    let url = `${this.apiUrl}/mooring/feetype/rule/${feeTypeId}/description`;
    return this.http.get<string>(url);
  }


  //getLeaseFees( leaseId:number): Observable<Fee> {
  //  let url = `${this.apiUrl}/mooring/leasetype/${feeTypeId}/fees`;
  //  return this.http.get<Fee>(url);
  //}


  getMooringList(leaseYear: number, leaseTypeId: number): Observable<MooringList[]> {
    let url = `${this.apiUrl}/mooring/list/${leaseYear}/${leaseTypeId}`;
    //let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImN1b25nIiwibmJmIjoxNTI1ODk5NjMxLCJleHAiOjE1MjU5MDA4MzEsImlhdCI6MTUyNTg5OTYzMX0.2ZMlwox-JwU1jiQjVPaH8MjlxmaO5xW1_IMPoSy9cdM";

    //let headers = new Headers({ 'Authorization': 'Bearer ' + token });
    //let options = new RequestOptions({ headers: headers });
    //let httpOptions = { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token }) };

    return this.http.get<MooringList[]>(url);
  }

  //as in agular tutorial, use array to avoid 404
  getMooringViewById(id: number): Observable<MooringView> {
    let url = `${this.apiUrl}/mooring/view/${id}`;
    //let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<MooringView>(url);
  }

  saveNoteAlert(body: Note): Observable<Note> {
    let url = `${this.apiUrl}/mooring/note`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Note>(url, body, httpOptions);
  }

  getNotesForPerson(id: number): Observable<Note[]> {
    let url = `${this.apiUrl}/mooring/person/${id}/note`;
    return this.http.get<Note[]>(url);
    //return this.http.get<Note[]>(url).pipe(
    //  tap(e => console.log('here!')),
    //  catchError(this.handleError)
    //);
  }

  getFilesForPerson(id: number): Observable<FileListModel[]> {
    let url = `${this.apiUrl}/mooring/person/${id}/file`;
    return this.http.get<FileListModel[]>(url);
    //return this.http.get<FileListModel[]>(url).pipe(
    //  tap(e => console.log('here!')),
    //  catchError(this.handleError)
    //);
  }

  savePerson(body: Person): Observable<Person> {
    let url = `${this.apiUrl}/mooring/owner`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<Person>(url, body, httpOptions);
  }

  getPersonById(id: number): Observable<Person> {
    let url = `${this.apiUrl}/mooring/owner/${id}`;
    return this.http.get<Person>(url);
  }

  getAddressByFk(id: number): Observable<Address[]> {
    let url = `${this.apiUrl}/mooring/owner/${id}/address`;
    return this.http.get<Address[]>(url);
  }

  saveAddress(id: number, body: Address): Observable<Address> {
    let url = `${this.apiUrl}/mooring/owner/${id}/address`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<Address>(url, body, httpOptions);
  }

  getAddressById(id: number, addressId: number): Observable<Address> {
    let url = `${this.apiUrl}/mooring/owner/${id}/address/${addressId}`;
    return this.http.get<Address>(url);
  }

  saveBoat(id: number, body: Boat): Observable<Boat> {
    let url = `${this.apiUrl}/mooring/owner/${id}/boat`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<Boat>(url, body, httpOptions);
  }

  getBoatById(id: number, boatId: number): Observable<Boat> {
    let url = `${this.apiUrl}/mooring/owner/${id}/boat/${boatId}`;
    return this.http.get<Boat>(url);
  }

  getMooringLeaseById(id: number, boatId: number, leaseId: number): Observable<MooringLease> {
    let url = `${this.apiUrl}/mooring/owner/${id}/boat/${boatId}/lease/${leaseId}`;
    return this.http.get<MooringLease>(url);
  }

  saveMooringLease(id: number, boatId: number, body: MooringLease): Observable<MooringLease> {
    let url = `${this.apiUrl}/mooring/owner/${id}/boat/${boatId}/lease`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post<MooringLease>(url, body, httpOptions);
  }

  getLeaseAccounts(id: number, boatId: number, leaseId: number, leaseYear: number): Observable<LeaseAccountModel[]> {
    let url = `${this.apiUrl}/mooring/owner/${id}/boat/${boatId}/lease/${leaseId}/account/${leaseYear}`;
    return this.http.get<LeaseAccountModel[]>(url);
  }

  getMooringApplicants(status): Observable<MooringApplicant[]> {
    let url = `${this.apiUrl}/mooring/applicant/list/${status}`;
    return this.http.get<MooringApplicant[]>(url);
  }

  getMooringApplicantById(id: number): Observable<MooringApplicant> {
    let url = `${this.apiUrl}/mooring/applicant/${id}`;
    return this.http.get<MooringApplicant>(url);
  }

  saveMooringApplicant(body: MooringApplicant): Observable<MooringApplicant> {
    let url = `${this.apiUrl}/mooring/applicant`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<MooringApplicant>(url, body, httpOptions);
  }

  getMooringLeaseFees(id: number, boatId: number, leaseId: number): Observable<Fee> {
    let url = `${this.apiUrl}/mooring/owner/${id}/boat/${boatId}/lease/${leaseId}`;
    return this.http.get<Fee>(url);
  }

  //*** END Mooring ***//

  //** USER **//

  getUserProfileModels(activeOnly: boolean): Observable<UserProfile[]> {
    let url = `${this.apiUrl}/user/list/${activeOnly}`;
    return this.http.get<UserProfile[]>(url);
  }

  getUserProfileById(id: number): Observable<UserProfile> {
    let url = `${this.apiUrl}/user/profile/${id}`;
    return this.http.get<UserProfile>(url);
  }

  saveUserProfile(body: UserProfile): Observable<UserProfile> {
    let url = `${this.apiUrl}/user/profile`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<UserProfile>(url, body, httpOptions);
  }

  savePasswordChange(body: UserPasswordChange): Observable<UserPasswordChange> {
    let url = `${this.apiUrl}/user/account/changepassword`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<UserPasswordChange>(url, body, httpOptions);
  }

  //** Reports  **//

  getIncidentStats(fromDate, toDate, departmentId: number): Observable<any[]> {
    let url = `${this.apiUrl}/data/incident/stats/${fromDate}/${toDate}/${departmentId}`;
    return this.http.get<any[]>(url);
  }

  getIncidentSearch(filter: IncidentSearchFilter): Observable<IncidentSearch[]> {
    let url = `${this.apiUrl}/data/incident/search`;

    let params = new HttpParams()
      .set('fromDate', moment(filter.fromDate).toDate().toDateString())
      .set('toDate', moment(filter.toDate).toDate().toDateString())
      .set('departmentId', filter.departmentId.toString())
      .set('incidentCategory', filter.incidentCategory)
      .set('searchFor', filter.searchFor)
      .set('searchIncidents', filter.searchIncidents.toString())
      .set('searchReports', filter.searchReports.toString())
      .set('searchViolations', filter.searchViolations.toString());

    //params = params.append('params', JSON.stringify(filter));

    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params };

    return this.http.get<any[]>(url, httpOptions);
  }

  initReportRequest(id: number): Observable<Lookup> {
    let url = `${this.apiUrl}/report/init/${id}`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Lookup>(url, "", httpOptions);
  }

  initReportRequestWithParams(id: number, body: any): Observable<Lookup> {
    let url = `${this.apiUrl}/report/init/${id}/parameters`;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Lookup>(url, body, httpOptions);
  }

  //getFileDownload(id: number): Observable<File> {
  //  let url = `${this.apiUrl}/file/${id}/download`;

  //  //let httpOptions = { headers: new HttpHeaders({ responseType: ResponseContentType.Blob}) };

  //  return this.http.get<File>(url, { responseType: ResponseContentType.Blob }).pipe(
  //    tap(data => {
  //      console.log('here');
  //    })
  //  );
  //}

  public async getFileDownload(id: number): Promise<Blob> {
    let url = `${this.apiUrl}/file/${id}/download`;
    const file = await this.http.get<Blob>(
      url, { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  //public async getStaticMap(url): Promise<any> {
  //  const file = await this.http.get<any>(url).toPromise();
  //  return file;
  //}

  public getStaticMap(url): any {
    return this.http.get<any>(url);
  }
  



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


}

