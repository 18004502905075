import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService } from './alert.service';
import { Note } from '../models/note';
import { DataService } from '../data.service';
import { DataTransport } from '../datatransport.provider';
import { Lookup } from '../models/lookup';
import { MatSnackBar } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'note-form',
  templateUrl: 'note.component.html',
  //styleUrls: ['logheader.component.css'],
})
export class NoteComponent {
  title = '';
  item: Note;
  departmentList: Lookup[] = [];
  departmentIds = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    
    private dataService: DataService,
    private data: DataTransport,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.item = new Note();
    const id = +this.route.snapshot.paramMap.get('id');

    if (this.data.storage == undefined || this.data.storage.personId == null) {
      this.router.navigate(['/moorings']);
    } else {
      this.item.fk_PersonId = this.data.storage.personId;
      this.item.fk_BoatId = this.data.storage.boatId;
      this.title = this.data.storage.title;

      this.getDepartmentList();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getDepartmentList(): void {
    this.dataService.getDepartmentLookups()
      .subscribe(
      itms => {
        if (itms != null) this.departmentList = itms;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.departmentList = [];
        console.log(error);
      });
  }

  saveForm(): void {

    var flag: boolean;
    console.log(this.departmentIds);

    this.item.departmentIds = [];
    for (var i = 0; i < this.departmentIds.length; i++) {
      var id = Number(this.departmentIds[i]);
      this.item.departmentIds.push(id);
    }
    var item = this.dataService.saveNoteAlert(this.item)
      .subscribe(result => {
        // Handle result
        //this.alertService.success("Update successful", true);
        this.openSnackBar("Update succesful", "Note");
        this.goBack();
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      });
    
  }

  goBack(): void {
    this.location.back();
  }
}
