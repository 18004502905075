import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorage } from '../login/tokenstorage.service';
import { environment } from '../../environments/environment';

import { DataService } from '../data.service';
import { ReportRequest } from '../models/reportrequest';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  moduleId: module.id,
  selector: 'main-navbar',
  templateUrl: 'main-navbar.component.html',
  //styles: [],
})
export class MainNavBarComponent {
  collapse: boolean = true;
  harborVisible: boolean = true;
  animalControlVisible: boolean = true;
  userLevel: number = 0;
  //collapse: boolean = true;

  constructor(private router: Router, private token: TokenStorage, private dataService: DataService) {
    let currentUser = this.token.getUser();
    this.userLevel = currentUser.userLevel;
    if (currentUser.userLevel > 1 && currentUser.departmentCode != 'HM') {
      this.harborVisible = false;
    }
    if (currentUser.userLevel > 1 && currentUser.departmentCode == 'HM') {
      this.animalControlVisible = false;
    }
  }


  //setUserLevel(): void {
  //  this.userLevel = this.token.userLevel();
  //}

  logout(): void {
    this.token.signOut();
    //this.setUserLevel();
    this.userLevel = 0;
    this.router.navigate(['dashboard/0']);
  }

  //serverReport(id): void {

  //}

  serverReport(id: number): void {

    this.dataService.initReportRequest(id)
      .subscribe(
      itms => {
        if (itms != null) {
          console.log(itms);
          let url = environment.reports + '/view/' + itms.id;
          window.open(url, '_blank');
        }
      },
      error => {
        console.log(error);
      });
  }

  openStaticMap(): void {

    let rawurl = 'https://maps.googleapis.com/maps/api/staticmap?size=600x400&maptype=hybrid&markers=size:mid|color:orange|label:!|41.920329801,-70.655928626&key=AIzaSyCg10UTz66urVHLx0Ml-CoWd2O41yRE0RE';
    let url = encodeURI(rawurl);
    console.log(url);

    window.open(url, '_blank');
  }
}
