import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MooringNavBarComponent } from './../mooring-navbar.component';
import { DataService } from '../../data.service';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { FeeType } from '../../models/feetype';


@Component({
  moduleId: module.id,
  selector: 'feetypes',
  templateUrl: 'feetypes.component.html'
  //styleUrls: ['mooringlist.component.css']
})
export class FeeTypesComponent {
  title = 'Fee Types';
  items: FeeType[] = [];
  feeDesc = '';
  private gridApi;
  private columnDefs;
  private context;
  private frameworkComponents;

  loading = false;
  feeTypeId: number = 0;
  private feeType = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private location: Location,
    private zone: NgZone
  ) {
    this.context = { componentParent: this };



    this.columnDefs = [
      //{
      //  headerName: 'No.', field: 'leaseTypeId', minWidth: 175, maxWidth: 200,
      //  cellRenderer: (params) => {
      //    var link = document.createElement('a');
      //    link.href = '#';
      //    link.innerText = params.data.leaseType;
      //    link.addEventListener('click', (e) => {
      //      e.preventDefault();
      //      this.leaseSelectEvent(params.value);
      //    });
      //    return link;
      //  }
      //},
      { headerName: 'Name', field: 'feeName', minWidth: 175, maxWidth: 200 },
      { headerName: 'Type', field: 'feeType', minWidth: 100, maxWidth: 150 },
      { headerName: 'Terms', field: 'terms', minWidth: 100, maxWidth: 150 },
      { headerName: 'Description', field: 'description', minWidth: 175, maxWidth: 250 },
      { headerName: 'Amount', field: 'defaultAmount', minWidth: 100, maxWidth: 125 }
    ];

  }

  ngOnInit(): void {
    this.getFeeTypes();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

  }
  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onRowClicked(event: any) {
    console.log(event.data.feeTypeId);
    this.feeTypeId = event.data.feeTypeId;
    this.feeType = event.data.feeType;
    this.refreshFeeDescription();
  }


  getFeeTypes(): void {
    this.dataService.getFeeTypes(false)
      .subscribe(
      itms => {
        if (itms != null) {
          this.items = itms;
          //this.refreshFees1();
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.items = [];
        console.log(error);
      });
  }

  getRuleFeeDescription(id): void {
    this.dataService.getRuleFeeDescription(id)
      .subscribe(
      itm => {
        if (itm != null) {
          this.feeDesc = itm;
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.feeDesc = '';
        //console.log(error);
      });
  }

  refreshFeeDescription(): void {
    if (this.feeTypeId > 0 && this.feeType == '[Rule]') {
      this.getRuleFeeDescription(this.feeTypeId);
    } else {
      this.feeDesc = '';
    }
  }

  feeSelectEvent(id): void {
    console.log(id);
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  goBack(): void {
    this.location.back();
  }

}
