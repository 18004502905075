import { Component, OnInit } from '@angular/core';
//import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { LogNavBarComponent } from './log-navbar.component';
//import { LogHeader } from '../models/logheader';
import { AnimalsInShelter } from '../models/animalsinshelter';
import { DataService } from '../data.service';
//import { LogHeaderService } from './logheader.service';
import { Lookup } from '../models/lookup';
//import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'animalsinshelter-form',
  templateUrl: 'animalsinshelter.component.html'
  //styleUrls: ['logheader.component.css'],
})
export class AnimalsInShelterComponent {
  title = '';
  loading = false;
  //logHeader: LogHeader = null;
  item: AnimalsInShelter = null;
  //reportingDay: Date;
  speciesList: Lookup[] = [];
  dispositionList: Lookup[] = [];

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.item = new AnimalsInShelter();
    this.getSpeciesList();
    this.getDispositionList();
    this.getAnimalsInShelter();
  }

  getSpeciesList(): void {
    this.dataService.getListReferenceLookups('animalspecies')
      .subscribe(
      itms => {
        if (itms != null) this.speciesList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.speciesList = [];
        console.log(error);
      });
  }

  getDispositionList(): void {
    this.dataService.getListReferenceLookups('animaldisposition')
      .subscribe(
      itms => {
        if (itms != null) this.dispositionList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.dispositionList = [];
        console.log(error);
      });
  }

  getAnimalsInShelter(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id === 0)
      return;
    this.dataService.getAnimalsInShelterById(id)
      .subscribe(
      itms => {
        if (itms != null) this.item = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  saveForm(): void {
    var flag: boolean;
    var item = this.dataService.saveAnimalsInShelter(this.item as AnimalsInShelter)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        //this.goBack();
        this.dataService.openSnackBar("Update succesful", "Animal-in-shelter");
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  goBack(): void {
    this.location.back();
  }
}
