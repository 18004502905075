import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';

//import { SomeClassOrInterface } from './interfaces';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
//import { ListValue } from './component/listvalue';
import { environment } from '../environments/environment';

@Injectable()
export class FileUploadService {

  //private apiUrl = 'https://www.harborplus.com/api'; 
  //private apiUrl = 'http://localhost:8738/api';
  private apiUrl = environment.api;
  personId = '';
  boatId = '';
  leaseId = '';

  constructor(
    private http: HttpClient
  ) { }


  postFile(fileToUpload: File, fileLabel: string): Observable<string> {
    let url = `${this.apiUrl}/file/upload`;

    //exclude 'Content-Type': 'multipart/form-data; charset=utf-8'
    //breaks with Angular2+
    let httpOptions = { headers: new HttpHeaders({}) };

    const formData: FormData = new FormData(); 
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    formData.append('personId', this.personId);
    formData.append('boatId', this.boatId);
    formData.append('leaseId', this.leaseId);
    formData.append('fileLabel', fileLabel);

    return this.http.post<string>(url, formData, httpOptions);
  }

}

