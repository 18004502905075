import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Boat } from '../models/boat';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';
import { MatSnackBar } from '@angular/material';
import { DataTransport } from '../datatransport.provider';
//
import { MooringTreeService } from "../components/mooringtree.service";
import { MooringTreeSelected } from '../models/mooringtreeselected';
import { Subscription } from 'rxjs/Subscription';

@Component({
  moduleId: module.id,
  selector: 'app-boatform',
  templateUrl: 'boat.component.html',
})
export class BoatComponent {
  title = '';
  item: Boat = null;
  personId: number = 0;
  boatId: number = 0;
  boatTypeList: Lookup[] = [];
  engineTypeList: Lookup[] = [];
  fuelTypeList: Lookup[] = [];
  private nodeSelectedSubscription: Subscription;
  @Input() IsChild: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private dataService: DataService,
    private treeService: MooringTreeService,
    private location: Location,
    private data: DataTransport
  ) {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id && id > 0) {
      this.personId = id;
      this.boatId = +this.route.snapshot.paramMap.get('boatId');
    }
  }

  ngOnInit(): void {
    this.nodeSelectedSubscription = this.treeService.observableSelected
      .subscribe(item => {
        this.nodeSelectedEvent(item as MooringTreeSelected);
      });

    if (this.data.storage != undefined && this.data.storage.title != null) {
      this.title = "Boat - " + this.data.storage.title;
    } else {
      this.title = "Boat";
    }
    this.item = new Boat();
    this.getBoatTypeList();
    this.getEngineTypeList();
    this.getFuelTypeList();
    this.getBoat();

  }

  ngOnDestroy() {
    // Unsubscribe when the component is destroyed
    this.nodeSelectedSubscription.unsubscribe();
  }

  nodeSelectedEvent(node: MooringTreeSelected): void {
    if (node == null)
      return;
    if (node.personId > 0 && node.boatId > 0) {
      this.personId = node.personId;
      this.boatId = node.boatId;
      this.getBoat();
    }
  }

  getBoatTypeList(): void {
    this.dataService.getListReferenceLookups('boat_type')
      .subscribe(
      itms => {
        if (itms != null) this.boatTypeList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.boatTypeList = [];
        console.log(error);
      });
  }

  getEngineTypeList(): void {
    this.dataService.getListReferenceLookups('enginetype')
      .subscribe(
      itms => {
        if (itms != null) this.engineTypeList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.engineTypeList = [];
        console.log(error);
      });
  }

  getFuelTypeList(): void {
    this.dataService.getListReferenceLookups('fueltype')
      .subscribe(
      itms => {
        if (itms != null) this.fuelTypeList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.fuelTypeList = [];
        console.log(error);
      });
  }

  getBoat(): void {
    if (this.boatId === 0)
      return;

    this.dataService.getBoatById(this.personId, this.boatId)
      .subscribe(
      itm => {
        if (itm != null) this.item = itm;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  saveForm(): void {
    this.item.fk_OwnerId = this.personId;
    var item = this.dataService.saveBoat(this.personId, this.item as Boat)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        //this.goBack();
        this.openSnackBar("Update succesful", "Boat");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  goBack(): void {
    this.location.back();
  }
}
