import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TokenStorage } from '../login/tokenstorage.service';
import { Person } from '../models/person';
import { MooringTreeService } from "./mooringtree.service";
import { Lookup } from '../models/lookup';
import { LookupWithParent } from '../models/lookupwithparent';
import { UserProfile } from 'src/app/models/userprofile';
import * as moment from 'moment';
import { MooringTreeView } from '../models/mooringtreeview';
import { MooringTreeSelected } from '../models/mooringtreeselected';
import { Subscription } from 'rxjs/Subscription';
import { MooringTreeModule } from 'src/app/components/mooringtree.module';
import { Observable } from 'rxjs/internal/Observable';
import { arch } from 'os';


@Component({
  //moduleId: module.id,
  selector: 'app-mooringtree',
  templateUrl: 'mooringtree.component.html',
  styleUrls: ['mooringtree.component.css']
})
export class MooringTreeComponent {
  title = '';
  mooringTreeView: MooringTreeView[] = [];
  private mooringTreeData: MooringTreeView[] = [];
  userList: Lookup[] = [];
  geoPositionLabel = '';
  userName = '';
  userLevel: number = null;
  currentDate: number = Date.now();
  private nodeSelected: MooringTreeSelected = null;
  private treeviewSubscription: Subscription;
  filterValue = '';

  constructor(
    private router: Router,
    private token: TokenStorage,
    private treeService: MooringTreeService,
    private location: Location,
    private route: ActivatedRoute,
    private zone: NgZone
  ) {
    this.userName = this.token.getUser().firstName + ' ' + this.token.getUser().lastName;
    this.userLevel = this.token.getUser().userLevel;
  }

  ngOnInit(): void {
    this.treeviewSubscription = this.treeService.observableTreeView
      .subscribe(item => {
        this.mooringTreeData = item;
        this.mooringTreeView = [...this.mooringTreeData];
      });
  }
  ngOnDestroy() {
    // Unsubscribe when the component is destroyed
    this.treeviewSubscription.unsubscribe();
  }

  treeNodeSelected(personId, boatId, leaseId): void {
    this.nodeSelected = new MooringTreeSelected();
    this.nodeSelected.personId = personId;
    this.nodeSelected.boatId = boatId;
    this.nodeSelected.leaseId = leaseId;
    //let s = this.nodeSelected.personId + ',' + this.nodeSelected.boatId + ',' + this.nodeSelected.leaseId;
    //this.treeService.openSnackBar(s, "clicked");
    this.treeService.selectMooringTreeNode(this.nodeSelected);
  }


  runFilter(target): void {
    //this.mooringTreeView = this.mooringTreeData.filter(t => t.fullName.indexOf(e.value, 0) >= 0);
    //this.mooringTreeView = [].concat(...this.mooringTreeData.map(t => t.fullName.indexOf(e.value, 0) >= 0));

    if (target.value == '') {
      this.mooringTreeView = [...this.mooringTreeData];
      return;
    } else if (target.value.toString().length <= 2) {
      return;
    }
    const e = target.value.toLowerCase();

    this.mooringTreeView = [];
    let flag: boolean;
    for (const p of this.mooringTreeData) {
      flag = false;
      if (p.fullName.toLowerCase().indexOf(e, 0) >= 0) { flag = true;}      
      if (!flag) {
        for (const b of p.vessels) {
          if (b.vessel.toLowerCase().indexOf(e, 0) >= 0) { flag = true; break; }
        }
      }
      if (!flag) {
        for (const b of p.vessels) {
          for (const l of b.mooringLeases) {
            if (l.leaseTypeDisplay.toLowerCase().indexOf(e, 0) >= 0) { flag = true; break; }
          }
        }
      }
      if (flag) {
        this.mooringTreeView.push(p);
      }      
    }
  }

  treeViewExpand(flag): void {
    console.log(flag);
    this.mooringTreeView.forEach(function (itm) {
      itm.expand = flag;
      itm.vessels.forEach(function (subitm) {
        subitm.expand = flag;
      });
    });
  }

  
  //tempNavigate(url: string): void {
  //  this.zone.run(() => {
  //    this.router.navigateByUrl(url);
  //  });
  //}



  goBack(): void {
    this.location.back();
  }
}
