import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpErrorResponse, HttpEvent
} from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { TokenStorage } from './tokenstorage.service';
import { ConsoleLoggerService } from '../components/console-logger.service';
//import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
//import 'rxjs/add/observable/of';
//https://www.tektutorialshub.com/angular-httpclient-using-http-interceptor/

//import { AlertService } from '../components/alert.service';
import { MatSnackBar } from '@angular/material';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private token: TokenStorage, private router: Router, private snackBar: MatSnackBar, private logger: ConsoleLoggerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let authReq = req;
    if (this.token.getToken() != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.token.getToken()) });
    }
    return next.handle(authReq)
      .map(resp => {
        // on Response
        if (resp instanceof HttpResponse) {
          // Do whatever you want with the response.
          return resp;
        }
      }).catch(err => {
        // onError
        this.logger.log(err);
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // redirect the user to login page
            // 401 unauthorised user
            this.token.signOut();
            this.router.navigate(['login/1']);
          }
        }
        var msg = err.message;
        if (err.error.message)
          msg = err.error.message;

        this.openSnackBar(msg, err.statusText);
        return EMPTY;
      });
     
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 8000,
    });
  }
}
