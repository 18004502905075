//import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
//import './rxjs-extensions';
import { MomentModule } from 'ngx-moment';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatToolbarModule,
  MatTooltipModule,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material';


import { AgGridModule } from 'ag-grid-angular';

import { ConfirmDialogModule } from '../components/confirm-dialog.module';

//components
import { LogNavBarComponent } from './log-navbar.component';

import { LogRoutingModule } from './log-routing.module';

import { LogHeaderService } from './logheader.service';
import { LogHeaderComponent } from './logheader.component';
import { OswComponent } from './osw.component';
import { IncidentLogComponent } from './incidentlog.component';
import { IncidentComponent } from './incident.component';
import { IncidentReportComponent } from './incidentreport.component';

import { InspectionsComponent } from './inspections.component';
import { OnDutyLogComponent } from './ondutylog.component';
import { LogPatrolBoatComponent } from './logpatrolboat.component';
import { LogAtDockComponent } from './logatdock.component';
import { AnimalsInShelterComponent } from './animalsinshelter.component';
import { AnimalsInShelterLogComponent } from './animalsinshelterlog.component';

//filters
import { DepartmentFilterPipe } from '../filters/department-filter';
import { LookuppFilterPipe } from '../filters/lookupp-filter';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatToolbarModule,
    MatTooltipModule,
  ]
})
export class MyMaterialModule { }

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    MomentModule,
    HttpClientModule,
    MyMaterialModule,
    AgGridModule.withComponents([]),
    LogRoutingModule,
    ConfirmDialogModule
  ],
  declarations: [
    DepartmentFilterPipe,
    LookuppFilterPipe,
    LogNavBarComponent,
    LogHeaderComponent,
    OswComponent,
    IncidentLogComponent,
    IncidentComponent,
    IncidentReportComponent,
    InspectionsComponent,
    OnDutyLogComponent,
    LogPatrolBoatComponent,
    LogAtDockComponent,
    AnimalsInShelterComponent,
    AnimalsInShelterLogComponent
  ],
  providers: [
    LogHeaderService
  ],
  exports: [DepartmentFilterPipe],
})
export class LogModule { }
