import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, fadeInContent } from '@angular/material';
import { DataService } from '../data.service';
import { TokenStorage } from './tokenstorage.service';
import { UserProfile } from '../models/userprofile'
import { UserPasswordChange } from '../models/userpasswordchange'
import { MatSnackBar } from '@angular/material';
import { ConsoleLoggerService } from '../components/console-logger.service';

@Component({
  moduleId: module.id,
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html'
})
export class ChangePasswordComponent {

  constructor(
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private data: DataService,
    private token: TokenStorage,
    private snackBar: MatSnackBar,
    private logger: ConsoleLoggerService) { }

  item: UserPasswordChange = null;

  confirmed = false;
  loginMessage = '';

  ngOnInit(): void {
    this.item = new UserPasswordChange();
    this.item.userId = this.token.getUser().userId;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  update(): void {

    this.confirmed = false;
    this.data.savePasswordChange(this.item)
      .subscribe(result => {
        this.item = result;
        if (this.item.success === true) {
          //this.goBack();
          this.openSnackBar("Password changed", "User Account");
          this.item = new UserPasswordChange();
          this.item.userId = this.token.getUser().userId;
        } else {
          this.openSnackBar("Change failed", "Error");
          this.loginMessage = "Error: " + this.item.message;
          this.confirmed = false;
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.logger.error(error);
        this.confirmed = false;
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  refreshConfirm(): void {
    if (this.validate()) {
      this.confirmed = true;
    } else {
      this.confirmed = false;
    }
  }

  private validate(): boolean {
    if (this.item.newPassword.length < 8)
      return false;

    if (this.item.newPassword != this.item.confirmPassword)
      return false;

    //let pattern = new RegExp("[^\P{ C }\s]");
    //let flag = pattern.test(this.item.newPassword);

    return true;
  }

  goBack(): void {
    this.location.back();
  }
}
