import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { LogNavBarComponent } from './log-navbar.component';
import { LogHeader } from '../models/logheader';
import { PatrolBoatInspection } from '../models/patrolboatinspection';
import { DataService } from '../data.service';
import { LogHeaderService } from './logheader.service';
import { Lookup } from '../models/lookup';

@Component({
  moduleId: module.id,
  selector: 'logpatrolboat-form',
  templateUrl: 'logpatrolboat.component.html',
  //styleUrls: ['logheader.component.css'],
})
export class LogPatrolBoatComponent {
  title = '';
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  item: PatrolBoatInspection = null;
  patrolBoatList: Lookup[] = [];

  constructor(
    private router: Router,
    private location: Location,   
    private dataService: DataService,
    private logHeaderService: LogHeaderService
  ) { }

  ngOnInit(): void {
    this.item = new PatrolBoatInspection();
    this.getLogHeader();  
  }

  getLogHeader(): void {
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
        this.getPatrolBoatList();
      },
      error => {
        console.log(error);
        this.router.navigate(['/log/header']);
      });
  }

  getPatrolBoatList(): void {
    this.dataService.getPatrolBoatLookups()
      .subscribe(
      itms => {
        if (itms != null) this.patrolBoatList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.patrolBoatList = [];
        console.log(error);
      });
  }

  saveForm(): void {
    var flag: boolean;
    this.item.fk_LogHeaderId = this.logHeader.logHeaderId;
    var item = this.dataService.savePatrolBoatInspection(this.item as PatrolBoatInspection)
      .subscribe(result => {
        // Handle result
        this.goBack();
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
    //this.statusLabel = 'saved';
  }

  goBack(): void {
    this.location.back();
  }
}
