import { Component, OnInit } from '@angular/core';
import { LogNavBarComponent } from './log-navbar.component';
//import { Router } from '@angular/router';
import { LogHeader } from '../models/logheader';
import { OnSceneWeather } from '../models/onsceneweather';
import { DataService } from '../data.service';
import { LogHeaderService } from './logheader.service';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { ExternalService } from '../external.service';
import { TidesAndCurrents, PredictionItem } from '../models/external/tidesandcurrents';
import { forEach } from '@angular/router/src/utils/collection';
import { WeatherPeriods } from '../models/external/weatherperiods';

@Component({
  moduleId: module.id,
  selector: 'my-logheader',
  templateUrl: 'logheader.component.html',
  styleUrls: [ 'logheader.component.css' ]
})
export class LogHeaderComponent implements OnInit {
  title = '';
  reportingDay = moment();
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  statusLabel = '';
  osw: OnSceneWeather[] = [];
  reportingDayMismatch = true;

  constructor(
    private dataService: DataService,
    private logHeaderService: LogHeaderService,
    private snackBar: MatSnackBar,
    private exService: ExternalService
  ) { }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnInit(): void {
    this.getLogHeader();    
  }

  onDateChange(): void {
    //this.title = "Reporting day " + this.reportingDay.toLocaleDateString();
    //console.log(this.reportingDay);
    this.logHeaderService.setReportingDay(this.reportingDay.toDate());
    this.getLogHeader();  
  }


  getLogHeader(): void {
    this.reportingDayMismatch = true;
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
        this.reportingDay = moment(this.logHeader.reportingDay);
        this.getOnSceneWeatherByFk();
        let dt1 = moment(new Date()).startOf('day');
        let dt2 = moment(this.logHeader.reportingDay).startOf('day');
        if (dt1.toISOString() != dt2.toISOString()) {
          this.reportingDayMismatch = true;
        } else { this.reportingDayMismatch = false;}
      },
      error => {
        console.log(error);
      });
  }

  getOnSceneWeatherByFk(): void {
    this.dataService.getOnSceneWeatherByFk(this.logHeader.logHeaderId)
      .subscribe(
      itms => {
        if (itms != null) this.osw = itms;
      },
      err => { this.osw = []; }
      );
  }


  saveForm(): void {
    this.logHeader.reportingDay = this.reportingDay.toDate();
    //this.logHeader.sunrise = new Date(this.reportingDay.toString().split('T')[0] + ' ' + this.sunriseTime);
    //var s = this.sunsetTime;
    var flag: boolean;
    var item = this.dataService.saveLogHeader(this.logHeader as LogHeader)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        this.logHeaderService.setReportingDay(this.reportingDay.toDate());
        this.getLogHeader();  
        this.openSnackBar("Update succesful", "Log Header");
      },
      error => {
        //this.errors = error;
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
    this.statusLabel = 'saved';
  }

  //*** external API

  getTides(): void {
    this.exService.getTidesAndCurrents(moment(this.logHeader.reportingDay).format("YYYY-MM-DD"), moment(this.logHeader.reportingDay).format("YYYY-MM-DD"))
      .subscribe(
      data => {
        if (data != null) {
          var item: TidesAndCurrents;
          item = <TidesAndCurrents>JSON.parse(data);
          this.setTides(item);        
        }
      },
      err => { console.log(err); }
      );
  }

  setTides(item:TidesAndCurrents): void {
    var t: PredictionItem;

    for (var i = 0; i < item.predictions.length; i++) {
      let p = item.predictions[i];
      let dt = moment(p.t);
      if (p.type == "H") {
        if (dt.get('hour') <= 12) {
          this.logHeader.aM_HighTide = dt.format("HH:mm");
        }
        else {
          this.logHeader.pM_HighTide = dt.format("HH:mm");
        }
      } else if(p.type == "L") {
        if (dt.get('hour') <= 12) {
          this.logHeader.aM_LowTide = dt.format("HH:mm");
        }
        else {
          this.logHeader.pM_LowTide = dt.format("HH:mm");
        }
      }
    }
  }

  getWeather(): void {
    this.exService.getTodaysWeather()
      .subscribe(
      data => {
        if (data != null) {
          var item: any;
          item = JSON.parse(data);
          this.setWeather(item);

        }
      },
      err => { console.log(err); }
      );
  }

  setWeather(item: WeatherPeriods): void {
    var t: PredictionItem;

    for (var i = 0; i < item.periods.length; i++) {
      let p = item.periods[i];
      let desc = '';
      if (p.number == 1 && (p.name == 'Today' || p.name == 'Tonight')) {
        desc = p.detailedForecast;
      } else if (p.number == 2 && (p.name == 'Today' || p.name == 'Tonight')) {
        desc = '\r\n' + p.detailedForecast;
      } else {
        break;
      }
      this.logHeader.landForecast = desc;
    }
  }

}
