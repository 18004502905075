import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogHeaderComponent }   from './logheader.component';
import { OswComponent } from './osw.component';
import { IncidentLogComponent } from './incidentlog.component';
import { IncidentComponent } from './incident.component';
import { IncidentReportComponent } from './incidentreport.component';

import { InspectionsComponent } from './inspections.component';
import { OnDutyLogComponent } from './ondutylog.component';
import { LogPatrolBoatComponent } from './logpatrolboat.component';
import { LogAtDockComponent } from './logatdock.component';
import { AnimalsInShelterComponent } from './animalsinshelter.component';
import { AnimalsInShelterLogComponent } from './animalsinshelterlog.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  { path: 'log/header', component: LogHeaderComponent, canActivate: [AuthGuard] },
  { path: 'log/osw', component: OswComponent, canActivate: [AuthGuard] },
  { path: 'log/incidentlog', component: IncidentLogComponent, canActivate: [AuthGuard] },
  { path: 'log/incident', component: IncidentComponent, canActivate: [AuthGuard] },
  { path: 'log/incident/:id', component: IncidentComponent, canActivate: [AuthGuard] },
  { path: 'log/incident/:id/report', component: IncidentReportComponent, canActivate: [AuthGuard] },
  { path: 'log/incident/:id/report/:reportId', component: IncidentReportComponent, canActivate: [AuthGuard] },
  { path: 'log/ondutylog', component: OnDutyLogComponent, canActivate: [AuthGuard] },
  { path: 'log/inspections', component: InspectionsComponent, canActivate: [AuthGuard] },
  { path: 'log/patrolboat', component: LogPatrolBoatComponent, canActivate: [AuthGuard] },
  { path: 'log/atdock', component: LogAtDockComponent, canActivate: [AuthGuard] },
  { path: 'log/inshelter', component: AnimalsInShelterComponent, canActivate: [AuthGuard]},
  { path: 'log/inshelter/:id', component: AnimalsInShelterComponent, canActivate: [AuthGuard] },
  { path: 'log/inshelterlog', component: AnimalsInShelterLogComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [ RouterModule ]
})
export class LogRoutingModule {}
