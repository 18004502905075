export class IncidentViolation {
  incidentViolationId: number;
  fk_IncidentReportId: number;
  ticketType: string;
  ticketNumber: string;
  officerName: string;
  officerID: string;
  offenseDate: Date;
  incidentLocation: string;
  regulationReference: string;
  regulationSubReference: string;
  reviewedByUserId: number;

  //model
  reviewedBy: string;
}
