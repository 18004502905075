import { Component, OnInit, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { TokenStorage } from '../login/tokenstorage.service';
//import { AlertService } from '../components/alert.service';
//import { LogNavBarComponent } from './log-navbar.component';
import { LogHeader } from '../models/logheader';
import { Incident } from '../models/incident';
import { IncidentReport } from '../models/incidentreport';
import { IncidentViolation } from '../models/incidentviolation';
import { ReportParameter } from '../models/reportparameter';
import { DataService } from '../data.service';
import { DataTransport } from '../datatransport.provider';
import { LogHeaderService } from './logheader.service';
import { Lookup } from '../models/lookup';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';
import { ConfirmDialogModule } from "../components/confirm-dialog.module";  
import { ConfirmDialogService } from '../components/confirm-dialog.service';

@Component({
  moduleId: module.id,
  selector: 'incidentreport-form',
  templateUrl: 'incidentreport.component.html',
  styleUrls: ['incidentreport.component.css']
})
export class IncidentReportComponent {
  title = '';
  reportingDay = moment();
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  incidentReports: IncidentReport[] = [];
  item: IncidentReport = new IncidentReport();
  itemv: IncidentViolation = new IncidentViolation();
  vehicleTypeList: Lookup[] = [];
  userList: Lookup[] = [];
  incidentRoleList: Lookup[] = [];
  licenseTypeList: Lookup[] = [];
  logIncident: Incident = new Incident();
  incidentDescription = "";
  dateOfBirthString = "";
  offenseDateString = "";
  reportId: number = 0;
  userLevel: number = null;


  constructor(
    // private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private token: TokenStorage,
    private dataService: DataService,
    private logHeaderService: LogHeaderService,
    private data: DataTransport,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.userLevel = this.token.getUser().userLevel;

    const id = +this.route.snapshot.paramMap.get('id');
    this.reportId = +this.route.snapshot.paramMap.get('reportId');
    if (id === 0) {
    //if (this.data.storage == undefined || this.data.storage == null) {
      this.router.navigate(['//log/incidentlog']);
      return;
    } else {
      //this.logIncident = this.data.storage;
      //if (this.logIncident.incidentDescription != null && this.logIncident.incidentDescription.length > 125) {
      //  this.incidentDescription = this.logIncident.incidentDescription.substring(0, 122) + '...';
      //} else {
      //  this.incidentDescription = this.logIncident.incidentDescription;
      //}
    }
    this.getVehicleTypeList();
    this.getLicenseTypeList();
    this.getIncidentRoleList();
    this.getUsersList();
    this.getIncident();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onDateChange(): void {
    //this.title = "Reporting day " + this.reportingDay.toLocaleDateString();
    console.log(this.reportingDay);
    //this.clearForm();
    this.logHeaderService.setReportingDay(this.reportingDay.toDate());
    this.getLogHeader();
  }


  getLogHeader(): void {
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
      },
      error => {
        console.log(error);
        this.router.navigate(['/log/header']);
      });
  }

  getIncident(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id === 0)
      return;
    this.dataService.getIncidentById(id)
      .subscribe(
      itms => {
        if (itms != null) {
          this.logIncident = itms;
          this.logHeaderService.setReportingDay(moment(this.logIncident.reportingDay).toDate());
          if (this.logIncident.incidentDescription != null && this.logIncident.incidentDescription.length > 125) {
            this.incidentDescription = this.logIncident.incidentDescription.substring(0, 122) + '...';
          } else {
            this.incidentDescription = this.logIncident.incidentDescription;
          }
          this.getLogHeader();
          this.getIncidentReports();
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  getUsersList(): void {
    this.dataService.getUserLookups()
      .subscribe(
      itms => {
        if (itms != null) this.userList = itms;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.userList = [];
        console.log(error);
      });
  }

  getIncidentRoleList(): void {
    this.dataService.getListReferenceLookups('incidentrole')
      .subscribe(
      itms => {
        if (itms != null) this.incidentRoleList = itms;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.incidentRoleList = [];
        console.log(error);
      });
  }

  getLicenseTypeList(): void {
    this.dataService.getListReferenceLookups('licensetype')
      .subscribe(
      itms => {
        if (itms != null) this.licenseTypeList = itms;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.licenseTypeList = [];
        console.log(error);
      });
  }

  getVehicleTypeList(): void {
    this.dataService.getListReferenceLookups('vehicletype')
      .subscribe(
      itms => {
        if (itms != null) this.vehicleTypeList = itms;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.vehicleTypeList = [];
        console.log(error);
      });
  }

  getIncidentReports(): void {
    if (this.logIncident.logIncidentId === 0 )
      return;
    this.dataService.getIncidentReports(this.logIncident.logIncidentId)
      .subscribe(
      itms => {
        if (itms != null && itms.length > 0) {
          for (let i = 0; i < itms.length; i++) {
            if (itms[i].incidentReportId == this.reportId) {
              this.item = itms[0];
              if (this.item.dateOfBirth != null) {
                this.dateOfBirthString = moment(this.item.dateOfBirth).format('YYYY-MM-DD');
              } else {
                this.dateOfBirthString = "";
              }
            }
           }
          this.incidentReports = itms;
          this.getIncidentViolation();
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  getIncidentViolation(): void {
    this.itemv = new IncidentViolation();
    if (this.logIncident.logIncidentId === 0 || this.item.incidentReportId == undefined || this.item.incidentReportId === 0)
      return;
    this.dataService.getIncidentViolations(this.item.incidentReportId)
      .subscribe(
      itms => {
        if (itms != null && itms.length > 0) {
          this.itemv = itms[0];
          if (this.itemv.offenseDate != null) {
            this.offenseDateString = moment(this.itemv.offenseDate).format('YYYY-MM-DD');
          }
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  saveReport(): void {

    var flag: boolean;
    this.item.fk_LogIncidentId = this.logIncident.logIncidentId;
    if (this.item.firstName) {
      this.item.reportTitle = this.item.incidentRole + ' ' + (this.item.firstName.toString() + ' ' + this.item.lastName).trim();
    } else {
      this.item.reportTitle = this.item.incidentRole + ' ' + this.item.lastName.trim();
    }

    if (moment(this.dateOfBirthString, 'YYYY-MM-DD', true).isValid()) {
      this.item.dateOfBirth = moment(this.dateOfBirthString).toDate();
    } else { this.item.dateOfBirth = null;}

    var e = this.dataService.saveIncidentReport(this.item as IncidentReport)
      .subscribe(result => {
        this.item = result;
        this.getIncidentReports();
        this.openSnackBar("Update succesful", "Incident Report");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
      });
  }

  saveViolation(): void {

    var flag: boolean;
    this.itemv.fk_IncidentReportId = this.item.incidentReportId;
    if (moment(this.offenseDateString, 'YYYY-MM-DD', true).isValid()) {
      this.itemv.offenseDate = moment(this.offenseDateString).toDate();
    } else { this.itemv.offenseDate = null; }

    var e = this.dataService.saveIncidentViolation(this.itemv as IncidentViolation)
      .subscribe(result => {
        this.itemv = result;
        this.openSnackBar("Update succesful", "Incident Violation");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
      });
  }

  //saveForms(): void {
  //  if (this.item.lastName) {
  //    this.saveReport();
  //  }
  //  if (this.itemv.ticketType) {
  //    this.saveViolation();
  //  }
  //}


  selectReport(): void {
    for (let i = 0; i < this.incidentReports.length; i++) {
      if (this.incidentReports[i].incidentReportId == this.reportId) {
        this.item = this.incidentReports[i];
        if (this.item.dateOfBirth != null) {
          this.dateOfBirthString = moment(this.item.dateOfBirth).format('YYYY-MM-DD');
        } else {
          this.dateOfBirthString = "";
        }
        let p = this.location.path();
        let v = p.split('/');
        v[v.length - 1] = this.reportId.toString();
        this.location.replaceState(v.join('/'));

      }
    }
    this.getIncidentViolation();
  }

  goToReport(id: number): void {
    if (id === 0) {
      var url = '/log/incident/' + this.logIncident.logIncidentId + '/report';
    } else {
      var url = '/log/incident/' + this.logIncident.logIncidentId + '/report/' + id;
    }
    this.data.storage = this.item;
    //this.router.navigateByUrl(url); //not navigate([url])
    this.tempNavigate(url);
  }


  openServerReport(id: number): void {
    if (this.incidentReports != null && this.incidentReports.length > 0) {

      let p: ReportParameter[];

      p = [
        { parameterName: "LogIncidentID", defaultValue: this.logIncident.logIncidentId.toString() }
      ];

      this.dataService.initReportRequestWithParams(id, p)
        .subscribe(
        itms => {
          if (itms != null) {
            console.log(itms);
            let url = environment.reports + '/view/' + itms.id;
            window.open(url, '_blank');
          }
        },
        error => {
          console.log(error);
        });
    }
  }

  showConfirmDialog() {
    this.confirmDialogService.confirmThis("Delete this report?", function () {
      alert("Yes clicked (feature not yet enabled)");
    }, function () {
      alert("No clicked (feature not yet enabled)");
    })
  }  

  goBack(): void {
    this.location.back();
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }
}
