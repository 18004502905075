import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { MainNavBarComponent } from '../dashboard/main-navbar.component';
import { UserProfile } from '../models/userprofile';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';

@Component({
  moduleId: module.id,
  selector: 'userlog',
  templateUrl: 'userlog.component.html'
})
export class UserLogComponent {
  title = '';
  loading = false;
  items: UserProfile[] = [];
  selectedFilterFlag: boolean = true;
  private columnDefs;
  private context;
  private frameworkComponents;
  private gridApi;

  constructor(
    private router: Router,
    private dataService: DataService,
    private zone: NgZone
  ) {
    this.context = { componentParent: this };
    //this.frameworkComponents = {
    //  childMessageRenderer: ChildMessageRenderer
    //};

    this.columnDefs = [
      {
        headerName: 'ID', field: 'userId', minWidth: 60, maxWidth: 60,
        cellRenderer: (params) => {
          var link = document.createElement('a');
          link.href = '#';
          link.innerText = params.value;
          link.addEventListener('click', (e) => {
            e.preventDefault();
            //console.log(params.data.mooringNumber);
            this.tempNavigate('/user/' + params.data.userId);
          });
          return link;
        }
      },
      { headerName: 'Login', field: 'userName', minWidth: 100, maxWidth: 100 },
      { headerName: 'Dept.', field: 'departmentCode', minWidth: 75, maxWidth: 75 },
      { headerName: 'First name', field: 'firstName', minWidth: 125, maxWidth: 125 },
      { headerName: 'Last name', field: 'lastName', minWidth: 125, maxWidth: 125 },
      { headerName: 'Phone', field: 'phone', minWidth: 125, maxWidth: 125 },
      { headerName: 'Email', field: 'emailAddress', minWidth: 125, maxWidth: 125 },
      {
        headerName: 'Active', field: 'isActive', minWidth: 125, maxWidth: 125,
        cellRenderer: (params) => {
          if (params.value === true) {
            return "Yes";
          }
          return "No";
        }}
      //{
      //  headerName: "",
      //  field: "shiftPlanDetailId",
      //  cellRenderer: "childMessageRenderer",
      //  colId: "params",
      //  width: 150
      //}
    ];

  }

  ngOnInit(): void {
    this.getUserProfileModels();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  methodFromParent(caller, params) {
    //alert("Parent Component Method from " + caller + " for value " + value);
    //this.router.navigate(['/scheduling/shiftplan/' + this.shiftPlanId.toString() + '/shift/' + params.value.toString()]);
    //this.openDialog(params.data);
  }

  getUserProfileModels(): void {
    this.dataService.getUserProfileModels(this.selectedFilterFlag)
      .subscribe(
      itms => {
        if (itms != null) this.items = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.items = [];
        console.log(error);
      });
  }

  filterChanged(): void {
    this.getUserProfileModels();
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

}
