export class OnDuty {
  logOnDutyId: number;
  fk_LogHeaderId: number;
  fk_UserId: number;
  startTime: string;
  endTime: string;
  notes: string;
  //model
  knownAs: string;
  departmentCode: string;
  
}
