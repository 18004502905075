import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookuppFilter',
  pure: false
})
export class LookuppFilterPipe implements PipeTransform {
  transform(items: any[], filter: string): any {
    if (!items || !filter || filter=='' || filter=='0') {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    //return items.filter(item => item.departmentId.indexOf(filter) !== -1);
    return items.filter(item => item.parentId == filter || item.parentId == '0');
  }
}
