export class MooringLease {
  leaseId: number;
  fk_LeaseTypeId: number;
  fk_BoatId: number;
  slip: number;
  leaseYear: number;
  mooringNumber: number;
  leaseStart: Date;
  leaseEnd: Date;
  transient: boolean;
  renewable: boolean;
  dredged: string;
  leaseLength: number;
  lengthUM: string;
  locationCode: string;
  locationGeneral: string;
  tenderLease: boolean;
  tenderNumber: string;
  tenderLength: string;
  tenderColor: string;
  mooringService: string;
  lastChangedDate: Date;
  mushroomWeight: number;
  chainSize: number;
  chainLength: number;
  fk_WaitingListId: number;
  latitude: number;
  longitude: number;

  createdBy: string;
  modifiedBy: string;
  createdDate: Date;
  modifiedDate: Date;
 
}
