export class ListReference {
  listReferenceId: number;
  fk_ReferenceGroupId: number;
  code: string;
  sortOrder: number;

  //
  groupName: string;
  groupDescription: string;
}
