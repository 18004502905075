export class Address {
  addressId: number;
  fk_PersonId: number;
  addressType: string;
  address1: string;
  address2: string;
  city: string;
  stateProv: string;
  postalCode: string;
  countryCode: string;
  isMailing: boolean;
  isPrimary: boolean;
  boatName: string;
}
