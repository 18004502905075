import { Component, OnInit, NgZone, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TokenStorage } from '../login/tokenstorage.service';
import { Person } from '../models/person';
import { Address } from '../models/address';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';
import { LookupWithParent } from '../models/lookupwithparent';
import { UserProfile } from 'src/app/models/userprofile';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';
import { DataTransport } from '../datatransport.provider';
//
import { MooringTreeService } from "../components/mooringtree.service";
import { MooringTreeSelected } from '../models/mooringtreeselected';
import { Subscription } from 'rxjs/Subscription';

@Component({
  //moduleId: module.id,
  selector: 'app-personform',
  templateUrl: 'person.component.html'
})
export class PersonComponent {
  title = '';
  item: Person = null;
  addresses: Address[] = [];
  itemAdd: Address = null;
  numAddresses: number = 0;
  currentIndex: number = 0;
  addressNavLabel = 'New';
  userList: Lookup[] = [];
  geoPositionLabel = '';
  userName = '';
  userLevel: number = null;
  currentDate: number = Date.now();
  personId: number = 0;
  countryCodeList: Lookup[] = [
    { 'id': 'US', 'label': 'United States' }
  ];
  addressTypeList: Lookup[] = [
    { 'id': 'Home', 'label': 'Home' },
    { 'id': 'Work', 'label': 'Work' },
    { 'id': 'Seasonal', 'label': 'Seasonal' }
  ];
  private nodeSelectedSubscription: Subscription;
  @Input() IsChild: boolean = false;

  constructor(
    private router: Router, 
    private token: TokenStorage,
    private dataService: DataService,
    private treeService: MooringTreeService,
    private location: Location,
    private snackBar: MatSnackBar,
    private data: DataTransport,
    private route: ActivatedRoute,
    private zone: NgZone
  ) {
    this.userName = this.token.getUser().firstName + ' ' + this.token.getUser().lastName;
    this.userLevel = this.token.getUser().userLevel;

    const id = +this.route.snapshot.paramMap.get('id');
    if(id && id > 0){
      this.personId = id;
    }
  }

  ngOnInit(): void {
    this.nodeSelectedSubscription = this.treeService.observableSelected
      .subscribe(item => {
        this.nodeSelectedEvent(item as MooringTreeSelected);
      });

    this.item = new Person();
    this.itemAdd = new Address();
    this.itemAdd.fk_PersonId = this.personId;
    this.itemAdd.countryCode = 'US';
    this.item.personType = 'OWNER';
    this.getPerson();
    //this.getAddresses();
  }

  ngOnDestroy() {
    // Unsubscribe when the component is destroyed
    this.nodeSelectedSubscription.unsubscribe();
  }

  nodeSelectedEvent(node: MooringTreeSelected): void {
    if (node == null)
      return;
    if (node.personId > 0) {
      this.personId = node.personId;
      this.getPerson();
      //this.getAddresses();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getPerson(): void {
    if (this.personId === 0)
      return;
    this.dataService.getPersonById(this.personId)
      .subscribe(
      itms => {
        if (itms != null) {
          this.item = itms;
          this.getAddresses();

        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  getAddresses(): void {
    if (this.personId === 0)
      return;
    this.dataService.getAddressByFk(this.personId)
      .subscribe(
      itms => {
        if (itms != null) {
          this.addresses = itms;
          this.numAddresses = this.addresses.length;
          this.itemAdd = this.addresses[0];
          this.currentIndex = 0;
          this.addressNavLabel = "Address " + (this.currentIndex + 1).toString() + " of " + this.numAddresses.toString();
        } else {
          this.itemAdd = new Address();
          this.itemAdd.fk_PersonId = this.personId;
          this.itemAdd.countryCode = 'US';
          this.numAddresses = 0;
          this.currentIndex = 0;
          this.addressNavLabel = 'New';
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.addresses = [];
        console.log(error);
      });
  }

  iterateAddress(val): void {
    if (val == 'up') {
      if (this.numAddresses > 1 && this.currentIndex != (this.numAddresses - 1)) {
        this.currentIndex = this.currentIndex + 1;
        this.itemAdd = this.addresses[this.currentIndex];
      }
    } else {
      if (this.numAddresses > 1 && this.currentIndex != 0) {
        this.currentIndex = this.currentIndex - 1;
        this.itemAdd = this.addresses[this.currentIndex];
      }
    }
    this.addressNavLabel = "Address " + (this.currentIndex + 1).toString() + " of " + this.numAddresses.toString();
  }

  saveForm(): void {

    var flag: boolean;

    var item = this.dataService.savePerson(this.item as Person)
      .subscribe(result => {
        this.openSnackBar("Update succesful", "Contact");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }


  //addAddress(): void {
  //  var txt = this.item.knownAs;
  //  this.data.storage = { title: txt };
  //  this.router.navigate([`mooring/owner/${this.item.personId}/address`]);
  //}

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  goBack(): void {
    this.location.back();
  }
}
