export class Note {
  noteId: number;
  fk_PersonId: number;
  fk_BoatId: number;
  notes: string;
  noteType: string;
  subType: string;
  isAlert: number;
  createdBy: string;
  modifiedBy: string;
  createdDate: Date;
  modifiedDate: Date;
  //model
  departmentIds: number[];
}
