export class Incident {
  logIncidentId: number;
  fk_LogHeaderId: number;
  primaryUserId: number;
  incidentTime: string;
  incidentCategory: string;
  incidentSubCategory: string;
  incidentDescription: string;
  latitude: number;
  longitude: number;
  //model
  reportingDay: Date;
  reportedBy: string;
  departmentId: number;
  departmentCode: string;
  hasReport: boolean;
  hasViolation: boolean;
}
