import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from '../models/userprofile'

const TOKEN_KEY = 'AuthToken';
const USER_KEY = 'AuthUser';

@Injectable()
export class TokenStorage {

  user: UserProfile = null;
  disableLogin: boolean = false;

  constructor(private router: Router) { }

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    if (this.disableLogin)
      return '';
    else
      return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(item: UserProfile) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(item));
  }

  public getUser(): UserProfile {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  public userLevel(): number {
    if (this.disableLogin)
      return 9;
    else
      var token = sessionStorage.getItem(TOKEN_KEY);
      var level = 0;
      if (token != null) {
        this.user = this.getUser();
        if (this.user != null)
          level = this.user.userLevel;
      }
      return level;
  }

}
