import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { LogNavBarComponent } from './log-navbar.component';
import { LogHeader } from '../models/logheader';
import { AtDock } from '../models/atdock';
import { DataService } from '../data.service';
import { LogHeaderService } from './logheader.service';
import { Lookup } from '../models/lookup';
import 'rxjs/add/operator/debounceTime';

@Component({
  moduleId: module.id,
  selector: 'logatdock-form',
  templateUrl: 'logatdock.component.html',
  //styleUrls: ['logheader.component.css'],
})
export class LogAtDockComponent {
  title = '';
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  item: AtDock = null;
  boatList: Lookup[] = [];

  constructor(
    private router: Router,
    private location: Location,   
    private dataService: DataService,
    private logHeaderService: LogHeaderService
  ) { }

  ngOnInit(): void {
    this.item = new AtDock();
    this.getLogHeader();
  }

  getLogHeader(): void {
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
        this.getBoatsAtDock();
      },
      error => {
        console.log(error);
        this.router.navigate(['/log/header']);
      });
  }

  getBoatsAtDock(): void {
    this.dataService.getBoatsAtDockLookups()
      .subscribe(
      itms => {
        if (itms != null) this.boatList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.boatList = [];
        console.log(error);
      });
  }

  saveForm(): void {

    var flag: boolean;
    this.item.fk_LogHeaderId = this.logHeader.logHeaderId;
    //this.item.boatName = this.myControl.value;
    var item = this.dataService.saveAtDock(this.item as AtDock)
      .subscribe(result => {
        // Handle result
        this.goBack();
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
    //this.statusLabel = 'saved';
  }

  goBack(): void {
    this.location.back();
  }
}
