export class IncidentReport {
  incidentReportId: number;
  fk_LogIncidentId: number;
  reportTitle: string;
  incidentRole: string;
  lastName: string;
  firstName: string;
  middleName: string;
  address1: string;
  address2: string;
  city: string;
  stateProv: string;
  postalCode: string;
  phone1: string;
  email1: string;
  dateOfBirth: Date;
  licenseType: string;
  licenseNumber: string;
  licenseCity: string;
  licenseStateProv: string;
  additionalLicenseType: string;
  additionalLicenseNumber: string;
  vehicleType: string;
  registration: string;
  vehicleMake: string;
  vehicleModel: string;
  reviewedByUserId: number;

  //model
  reviewedBy: string;
}
