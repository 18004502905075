import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { TokenStorage } from '../login/tokenstorage.service';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment';
import { cursorTo } from 'readline';

@Component({
  moduleId: module.id,
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent {
  boatRegistration = '';
  mooringNumber: number;
  mooringSearchFor = '';
  findMessage = '';
  userName = '';
  harborVisible: boolean = true;
  animalControlVisible: boolean = true;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private token: TokenStorage,
    private dataService: DataService
  ) {
    let currentUser = this.token.getUser();
    this.userName = currentUser.firstName + ' ' + currentUser.lastName;
    if (currentUser.userLevel > 1 && currentUser.departmentCode != 'HM') {
      this.harborVisible = false;
    }
    if (currentUser.userLevel > 1 && currentUser.departmentCode == 'HM') {
      this.animalControlVisible = false;
    }
  }

  ngOnInit(): void {
    const isNew = +this.route.snapshot.paramMap.get('flag');
    //if (isNew) {
    //  this.dataService.initLogHeader();
    //}
    console.log(environment.api);
  }

  findMooringLeaseAll(): void {
    this.router.navigate(['/moorings/' + this.mooringSearchFor]);
  }

  findMooringLease(): void {
    this.dataService.findMooringLeaseId(this.boatRegistration, this.mooringNumber)
      .subscribe(
      itm => {
        if (itm == null || itm == 0) {
          this.findMessage = 'Not found';
        } else {
          this.router.navigate(['/moorings/' + itm.toString()]);
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        console.log(error);
      });

  }
}
