import { NgModule } from '@angular/core';  
import { BrowserModule } from '@angular/platform-browser';

import {MooringTreeComponent} from './mooringtree.component';  
import {MooringTreeService} from './mooringtree.service';  
  
@NgModule({
  imports: [
    BrowserModule
    ],
    declarations: [  
      MooringTreeComponent  
    ],  
    exports: [  
      MooringTreeComponent  
    ],providers:[  
      MooringTreeService  
    ]  
})  
export class MooringTreeModule  
{  
}  
