import { Component, OnInit } from '@angular/core';
//import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { MainNavBarComponent } from '../dashboard/main-navbar.component';
import { UserProfile } from '../models/userprofile';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';
//import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'userprofile-form',
  templateUrl: 'userprofile.component.html'
  //styleUrls: ['logheader.component.css'],
})
export class UserProfileComponent {
  title = '';
  loading = false;
  item: UserProfile = null;
  departmentList: Lookup[] = [];
  userLevelList: any[] = [
    { 'id': 3, 'label': 'Staff' },
    { 'id': 2, 'label': 'Manager' }
  ];

  constructor(
    // private route: ActivatedRoute,
    private location: Location,
    private route: ActivatedRoute,
    private dataService: DataService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.item = new UserProfile();
    this.item.userId = 0;
    this.item.userLevel = 3;
    this.getDepartmentList();
    this.getUserProfile();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getDepartmentList(): void {
    this.dataService.getDepartmentLookups()
      .subscribe(
      itms => {
        if (itms != null) {
          this.departmentList = itms;
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.departmentList = [];
        console.log(error);
      });
  }

  getUserProfile(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id === 0)
      return;
    this.dataService.getUserProfileById(id)
      .subscribe(
      itms => {
        if (itms != null) this.item = itms;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  saveForm(): void {
    var flag: boolean;
    var item = this.dataService.saveUserProfile(this.item as UserProfile)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        //this.goBack();
        this.openSnackBar("Update succesful", "User Profile");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  goBack(): void {
    this.location.back();
  }
}
