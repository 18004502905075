//https://www.c-sharpcorner.com/article/confirm-dialog-in-angular-using-bootstrap-modal/
import { NgModule } from '@angular/core';  
import { BrowserModule } from '@angular/platform-browser';

import {ConfirmDialogComponent} from './confirm-dialog.component';  
import {ConfirmDialogService} from './confirm-dialog.service';  
  
@NgModule({
  imports: [
    BrowserModule
    ],
    declarations: [  
        ConfirmDialogComponent  
    ],  
    exports: [  
        ConfirmDialogComponent  
    ],providers:[  
       ConfirmDialogService  
    ]  
})  
export class ConfirmDialogModule  
{  
}  
