import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TokenStorage } from '../login/tokenstorage.service';
//import { AlertService } from '../components/alert.service';
import { DataService } from '../data.service';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Lookup } from '../models/lookup';
import { DepartmentDialog } from '../components/department-dialog';

@Component({
  moduleId: module.id,
  selector: 'incidentlog',
  templateUrl: 'incidentstats.component.html'
})
export class IncidentStatsComponent {
  title = '';
  loading = false;
  items: any = [];
  fromDate = moment().add(-7, 'days');
  toDate = moment();
  departmentList: Lookup[] = [];
  departmentFilter = '';

  constructor(
    private router: Router,
    private location: Location,   
    private token: TokenStorage,
    private dataService: DataService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    let currentUser = this.token.getUser();
    if (currentUser.userLevel > 1)
      this.departmentFilter = currentUser.departmentId.toString();
    this.getDepartmentList();
    this.getIncidentStats();
  }

  getDepartmentList(): void {
    this.dataService.getDepartmentLookups()
      .subscribe(
      itms => {
        if (itms != null) {
          itms.unshift({ id: '', label: 'All departments' });
          this.departmentList = itms;
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.departmentList = [];
        console.log(error);
      });
  }

  getIncidentStats(): void {

    let deptId = 0;
    if (this.departmentFilter !== '') {
      deptId = Number(this.departmentFilter);
    }
    this.dataService.getIncidentStats(moment(this.fromDate).toDate().toDateString(), moment(this.toDate).toDate().toDateString(), deptId)
      .subscribe(
      itms => {
        if (itms != null) this.items = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.items = [];
        console.log(error);
      });
  }

  openDeptDialog(): void {
    let dialogRef = this.dialog.open(DepartmentDialog, {
      width: '270px',
      data: { title: "Department filter", departmentList: this.departmentList, departmentId: this.departmentFilter }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result == undefined) {
        //result = '';
        this.departmentFilter = '';
      } else {
        this.departmentFilter = result;
      }
      //this.dataSource.filter = this.departmentFilter;
      //filter at db
      this.getIncidentStats();


    });
  }
 
  goBack(): void {
    this.location.back();
  }
}
