export class UserProfile {
  userId: number;
  fk_DepartmentId: number;
  userName: string;
  domainUser: string;
  location: string;
  firstName: string;
  lastName: string;
  phone: string;
  emailAddress: string;
  userLevel: number;
  role: string;
  isEmployee: boolean;
  isActive: boolean;
  //
  departmentId: number;
  departmentCode: string;
  departmentName: string;

}
