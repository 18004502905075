export class MooringApplicant {
  applicantId: number;
  fk_waitinglistid: number;
  applicationStatus: string;
  placenumber: number;
  lastName: string;
  firstName: string;
  middleName: string;
  fullName: string;
  address1: string;
  address2: string;
  city: string;
  stateProv: string;
  postalCode: string;
  phone1: string;
  phone2: string;
  email1: string;
  resident: boolean;
  addressType: string;
  boatName: string;
  boatLength: number;
  boatType: string;
  commercial: boolean;
  slipRequested: boolean;
  locationRequested: string;
  notes: string;
  applicationDate: Date;
  lastRenewed: Date;
  completeDate: Date;
  associationId: number;
  fk_PersonId: number;
}
