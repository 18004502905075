import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TokenStorage } from '../login/tokenstorage.service';
import { Person } from '../models/person';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';
import { LookupWithParent } from '../models/lookupwithparent';
import { UserProfile } from 'src/app/models/userprofile';
import * as moment from 'moment';
import { MooringTreeModule } from "../components/mooringtree.module";
import { MooringTreeService } from "../components/mooringtree.service"; 
import { MooringTreeSelected } from '../models/mooringtreeselected';
import { Subscription } from 'rxjs/Subscription';
import { LeaseType } from '../models/leasetype';

@Component({
  moduleId: module.id,
  selector: 'mooringtreeview-form',
  templateUrl: 'mooringtreeview.component.html'
})
export class MooringTreeViewComponent {
  title = '';
  loading: boolean = false;
  leaseYear: number;
  leaseYearList: number[] = [];
  leaseTypeId: number;
  leaseTypes: LeaseType[] = [];
  userName = '';
  userLevel: number = null;
  private nodeSelected: MooringTreeSelected = new MooringTreeSelected(); 
  private nodeSelectedSubscription: Subscription;
  tmpNodeDisplay = '';
  editFormEnum: number = 0 //person=1, boat=2, lease=3
  buttonNewLabel = '';
  buttonAddLabel = '';

  constructor(
    private router: Router, 
    private token: TokenStorage,
    private dataService: DataService,
    private treeService: MooringTreeService,
    private location: Location,
    private route: ActivatedRoute,
    private zone: NgZone
  ) {
    this.userName = this.token.getUser().firstName + ' ' + this.token.getUser().lastName;
    this.userLevel = this.token.getUser().userLevel;
    this.nodeSelected.leaseId = 0;
    this.nodeSelected.boatId = 0;
    this.nodeSelected.personId = 0;
  }

  ngOnInit(): void {
    this.nodeSelectedSubscription = this.treeService.observableSelected
      .subscribe(item => {
        this.nodeSelectedEvent(item as MooringTreeSelected);
      });

    this.getLeaseTypes();
    let thisYear = new Date().getFullYear();
    this.leaseYearList = [thisYear, thisYear - 1, thisYear - 2, thisYear - 3];
    this.leaseYear = new Date().getFullYear();
    this.leaseTypeId = 8;
    //this.treeService.refreshMooringTreeView(this.leaseYear, this.leaseTypeId);
  }

  ngOnDestroy() {
    // Unsubscribe when the component is destroyed
    this.nodeSelectedSubscription.unsubscribe();
  }

  nodeSelectedEvent(node: MooringTreeSelected): void {
    if (node == null)
      return;
    this.nodeSelected = node;
    this.tmpNodeDisplay = this.nodeSelected.personId + ',' + this.nodeSelected.boatId + ',' + this.nodeSelected.leaseId;
    if (this.nodeSelected.isLeaseFee == false) {
      if (this.nodeSelected.boatId == 0) {
        this.editFormEnum = 1;
        this.buttonNewLabel = 'Owner';
        this.buttonAddLabel = 'Boat';
      } else if (this.nodeSelected.leaseId == 0) {
        this.editFormEnum = 2;
        this.buttonNewLabel = 'Boat';
        this.buttonAddLabel = 'Mooring';
      } else {
        this.editFormEnum = 3;
        this.buttonNewLabel = 'Mooring';
        this.buttonAddLabel = '';
      }
    } else {
      this.editFormEnum = 4;
      this.buttonNewLabel = 'Fee';
      this.buttonAddLabel = '';
    }
  }
  
  refreshTreeView(): void {
    this.treeService.refreshMooringTreeView(this.leaseYear, this.leaseTypeId);
    this.editFormEnum = 0;
  }

  getLeaseTypes(): void {
    this.dataService.getLeaseTypes(false)
      .subscribe(
      itms => {
        if (itms != null) this.leaseTypes = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.leaseTypes = [];
        console.log(error);
      });
  }

  showLeaseFees(): void {
    this.nodeSelected.isLeaseFee = true;
    this.treeService.selectMooringTreeNode(this.nodeSelected);
  }

  //tempNavigate(url: string): void {
  //  this.zone.run(() => {
  //    this.router.navigateByUrl(url);
  //  });
  //}

  goBack(): void {
    this.location.back();
  }
}
