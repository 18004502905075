import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TokenStorage } from '../login/tokenstorage.service';
//import { AlertService } from '../components/alert.service';
import { MooringNavBarComponent } from './mooring-navbar.component';
import { DataService } from '../data.service';
import { DataTransport } from '../datatransport.provider';
import { MooringList } from '../models/mooringlist';
import { LeaseType } from '../models/leasetype';

import * as moment from 'moment';
//import { HttpClient } from '@angular/common/http';
//import { forEach } from '@angular/router/src/utils/collection';
import { MooringActionRenderer } from "../aggrid/mooring-action-renderer.component";
//import { Lookup } from 'src/app/models/lookup';

@Component({
  moduleId: module.id,
  selector: 'mooringlist',
  templateUrl: 'mooringlist.component.html'
  //styleUrls: ['mooringlist.component.css']
})
export class MooringListComponent {
  title = '';
  items: MooringList[] = [];
  leaseYear: number;
  leaseYearList: number[] = [];
  leaseTypeId: number;
  leaseTypes: LeaseType[] = [];
  private gridApi;
  private columnDefs;
  private context;
  private frameworkComponents;
  loading = false;
  searchFor = '';


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private token: TokenStorage,
    private dataService: DataService,
    private zone: NgZone,
    private data: DataTransport
  ) {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      mooringActionRenderer: MooringActionRenderer
    };

    let actionWidth = 150;
    if (token.getUser().userLevel > 2) {
      actionWidth = 0;
    }
    this.columnDefs = [
      {
        headerName: 'No.', field: 'mooringNumber', minWidth: 50, maxWidth: 125,
        cellRenderer: (params) => {
          var link = document.createElement('a');
          link.href = '#';
          link.innerText = params.value;
          link.addEventListener('click', (e) => {
            e.preventDefault();
            this.tempNavigate('/mooring/' + params.data.leaseId);
          });
          return link;
        }
      },
      { headerName: 'Owner', field: 'fullName', minWidth: 150, maxWidth: 200 },
      { headerName: 'Vessel', field: 'vessel', minWidth: 150, maxWidth: 200 },
      { headerName: 'Registration', field: 'registration', minWidth: 100, maxWidth: 125 },
      { headerName: 'Lease Type', field: 'leaseType', minWidth: 100, maxWidth: 150 },
      {
        headerName: "",
        field: "leaseId",
        cellRenderer: "mooringActionRenderer",
        colId: "params",
        width: actionWidth,
        hide: (actionWidth == 0)
      }
    ];
  }

  ngOnInit(): void {

    this.getLeaseTypes();
    let thisYear = new Date().getFullYear();
    this.leaseYearList = [ thisYear, thisYear-1, thisYear-2, thisYear-3 ];
    this.leaseYear = new Date().getFullYear();
    this.leaseTypeId = 8;
    this.getMooringList(this.leaseYear, this.leaseTypeId);

    this.searchFor = this.route.snapshot.paramMap.get('searchFor');
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

    if (this.searchFor !== null && this.searchFor.length >= 1) {
      this.filterChanged(this.searchFor);
    }
  }
  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  methodFromParent(caller, params) {
    console.log(params);
    if (caller == 'lease') {
      //alert("Parent1 Component Method from " + caller + " for value " + params.value);
      this.data.storage = { title: params.data.fullName.toString() };
      this.tempNavigate('/mooring/owner/' + params.data.personId.toString() + '/boat/' + params.data.boatId.toString() + '/lease/' + params.data.leaseId.toString() );
    } else if (caller == 'boat') {
      //alert("Parent2 Component Method from " + caller + " for value " + params.value);
      this.data.storage = { title: params.data.fullName.toString() };
      this.tempNavigate('/mooring/owner/' + params.data.personId.toString() + '/boat/' + params.data.boatId.toString());
    } else if (caller == 'owner') {
      //alert("Parent3 Component Method from " + caller + " for value " + params.value);
      this.tempNavigate('/mooring/owner/' +  params.data.personId.toString());
    }
  }

  getDateDiff(startDt: Date): number {
    //var msMinute = 60 * 1000,
    //  msDay = 60 * 60 * 24 * 1000,
    //  current = new Date();
    //var diff = Math.floor(((current - compareDt) % msDay) / msMinute);
    //return diff;
    if (startDt == null)
      return 0;

    var current = new Date();
    var duration = moment.duration(moment(current).diff(startDt));
    var diff = duration.asMinutes();
    return diff;
  }

  getLeaseTypes(): void {
    this.dataService.getLeaseTypes(false)
      .subscribe(
      itms => {
        if (itms != null) this.leaseTypes = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.leaseTypes = [];
        console.log(error);
      });
  }

  getMooringList(leaseYear: number, leaseTypeId: number): void {
    this.loading = true;

    if (this.dataService.mooringListContainer.items != null &&
      this.getDateDiff(this.dataService.mooringListContainer.dateSet) < 60) {
      this.items = this.dataService.mooringListContainer.items;
      this.afterDataInit();
      this.loading = false;
      return;
    }

    this.dataService.getMooringList(leaseYear, leaseTypeId)
      .subscribe(
      itms => {
        if (itms != null) {
          this.items = itms;
        } else {
          this.items = [];
        };
        this.afterDataInit();
        this.loading = false;
      },
      error => {
        this.items = [];
        this.dataService.openSnackBar("error.message", "Error");
        console.log(error);
        this.loading = false;
      });
  }

  afterDataInit() {
    this.dataService.mooringListContainer.items = this.items;
    this.dataService.mooringListContainer.dateSet = new Date();   
  }

  filterChanged(val) {
    this.gridApi.setQuickFilter(val);
  }

  refreshList(): void {
    this.dataService.mooringListContainer.items = null;
    this.getMooringList(this.leaseYear, this.leaseTypeId);
  }

  showColumn(): boolean {
    return false;
    //{'hidden-item': showColumn()}
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

}
