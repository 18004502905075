import { Component } from '@angular/core';
import { TokenStorage } from '../login/tokenstorage.service';
import { environment } from '../../environments/environment';

import { DataService } from '../data.service';
import { ReportRequest } from '../models/reportrequest';

@Component({
  moduleId: module.id,
  selector: 'mooring-navbar',
  templateUrl: 'mooring-navbar.component.html',
  styles: []
})
export class MooringNavBarComponent {
  collapse: boolean = true;
  harborVisible: boolean = true;

  constructor(private token: TokenStorage, private dataService: DataService) {
    let currentUser = this.token.getUser();
    if (currentUser.userLevel > 1 && currentUser.departmentCode != 'HM') {
      this.harborVisible = false;
    }
  }

  serverReport(id: number): void {

    this.dataService.initReportRequest(id)
      .subscribe(
      itms => {
        if (itms != null) {
          console.log(itms);
          let url = environment.reports + '/view/' + itms.id;
          window.open(url, '_blank');
        }
      },
      error => {
        console.log(error);
      });
  }
}
