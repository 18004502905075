import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MooringListComponent } from './mooringlist.component';
import { MooringViewComponent } from './mooringview.component';
import { NoteComponent } from '../components/note.component';
import { FileComponent } from '../components/file.component';
import { WaitlistComponent } from './applicant/waitlist.component';
import { WaitlistGridComponent } from './applicant/waitlistgrid.component';
import { PersonComponent } from './person.component';
import { AddressComponent } from './address.component';
import { BoatComponent } from './boat.component';
import { MooringLeaseComponent } from './mooringlease.component';
import { MooringTreeViewComponent } from './mooringtreeview.component';
import { LeaseTypesComponent } from './setup/leasetypes.component';
import { FeeTypesComponent } from './setup/feetypes.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  { path: 'moorings', component: MooringListComponent, canActivate: [AuthGuard] },
  { path: 'mooring/applicant', component: WaitlistComponent, canActivate: [AuthGuard] },
  { path: 'mooring/applicant/:id', component: WaitlistComponent, canActivate: [AuthGuard] },
  { path: 'mooring/waitinglist', component: WaitlistGridComponent, canActivate: [AuthGuard] },
  { path: 'moorings/:searchFor', component: MooringListComponent, canActivate: [AuthGuard] }, /* must go near end*/
  { path: 'mooring/:id', component: MooringViewComponent, canActivate: [AuthGuard] },
  { path: 'mooring/:id/note', component: NoteComponent, canActivate: [AuthGuard] },
  { path: 'mooring/:id/file', component: FileComponent, canActivate: [AuthGuard] },
  { path: 'mooring/owner', component: PersonComponent, canActivate: [AuthGuard] },
  { path: 'mooring/owner/:id', component: PersonComponent, canActivate: [AuthGuard] },
  { path: 'mooring/owner/:id/address', component: AddressComponent, canActivate: [AuthGuard] },
  { path: 'mooring/owner/:id/address/:addressId', component: AddressComponent, canActivate: [AuthGuard] },
  { path: 'mooring/owner/:id/boat', component: BoatComponent, canActivate: [AuthGuard] },
  { path: 'mooring/owner/:id/boat/:boatId', component: BoatComponent, canActivate: [AuthGuard] },
  { path: 'mooring/owner/:id/boat/:boatId/lease', component: MooringLeaseComponent, canActivate: [AuthGuard] },
  { path: 'mooring/owner/:id/boat/:boatId/lease/:leaseId', component: MooringLeaseComponent, canActivate: [AuthGuard] },
  { path: 'mooring/list/treeview', component: MooringTreeViewComponent, canActivate: [AuthGuard] },
  { path: 'mooring/setup/leasetypes', component: LeaseTypesComponent, canActivate: [AuthGuard] },
  { path: 'mooring/setup/feetypes', component: FeeTypesComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [ RouterModule ]
})
export class MooringRoutingModule {}
