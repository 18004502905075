import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
//import { AlertService } from '../components/alert.service';
import { LogNavBarComponent } from './log-navbar.component';
import { LogHeader } from '../models/logheader';
import { OnSceneWeather } from '../models/onsceneweather';
import { DataService } from '../data.service';
import { LogHeaderService } from './logheader.service';
import { Lookup } from '../models/lookup';

@Component({
  moduleId: module.id,
  selector: 'osw-form',
  templateUrl: 'osw.component.html',
  styleUrls: ['logheader.component.css'],
})
export class OswComponent {
  title = '';
  logHeader: LogHeader = new LogHeader(); //prevent error on init
  osw: OnSceneWeather = null;
  stationList: Lookup[] = [];

  constructor(
    // private route: ActivatedRoute,
    // private location: Location,
    private router: Router,
    private dataService: DataService,
    private logHeaderService: LogHeaderService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.osw = new OnSceneWeather();
    this.getLogHeader();
  }

  getLogHeader(): void {
    this.logHeaderService.getLogHeader()
      .subscribe(
      log => {
        this.logHeader = log;
        //osw
        this.getWeatherStationList();
        this.getOnSceneWeather();
      },
      error => {
        console.log(error);
        this.router.navigate(['/log/header']);
      });
  }

  getOnSceneWeather(): void {
    this.dataService.getOnSceneWeather(this.logHeader.logHeaderId)
      .subscribe(
      itm => {
        if (itm != null && itm.logWeatherId > 0) this.osw = itm;
      },
        error => {
          this.dataService.openSnackBar("error.message", "Error");
          this.osw = new OnSceneWeather();
          console.log(error);
        });
  }

  getWeatherStationList(): void {
    this.dataService.getListReferenceLookups('weatherstation')
      .subscribe(
      itms => {
        if (itms != null) this.stationList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.osw = new OnSceneWeather();
        console.log(error);
      });
  }

  saveForm(): void {

    var flag: boolean;
    this.osw.fk_LogHeaderId = this.logHeader.logHeaderId;
    var item = this.dataService.saveOnSceneWeather(this.osw as OnSceneWeather)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        this.goBack();
      },
      error => {
        //this.errors = error;
        this.dataService.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
    //this.statusLabel = 'saved';
  }

  goBack(): void {
    this.location.back();
  }
}
