import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
//import { AlertService } from '../components/alert.service';
import { DataService } from '../data.service';

import { ListReferenceGroup } from '../models/listreferencegroup';
import { ListReference } from '../models/listreference';

import * as moment from 'moment';
//import { MatSnackBar } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'listreference',
  templateUrl: 'listreference.component.html'
})
export class ListReferenceComponent {
  title = '';
  editMode = 0;
  editModeLabel = '';
  groupList: ListReferenceGroup[] = [];
  items: ListReference[] = [];
  group = new ListReferenceGroup();
  item = new ListReference();
  groupId = 0;
  columnDefs;
  context;
  loading = false;

  constructor(
    private router: Router,
    private dataService: DataService
  ) {
    this.context = { componentParent: this };
    //this.frameworkComponents = {
    //  childMessageRenderer: ChildMessageRenderer
    //};

    this.columnDefs = [
      { headerName: 'Group', field: 'groupDescription', width: 175 },
      { headerName: 'Code', field: 'code', width: 175 }
    ];
  }

  ngOnInit(): void {
    this.getListReferenceGroups();

  }

  onGridReady(params) {
    //this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;

    //params.api.sizeColumnsToFit();
  }


  getListReferenceGroups(): void {
    this.dataService.getListReferenceGroups()
      .subscribe(
      itms => {
        if (itms != null) this.groupList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.groupList = [];
        //console.log(error);
      });
  }

  getListReferences(): void {

    if (this.groupId == 0)
      return;

    this.dataService.getListReferencesById(this.groupId)
      .subscribe(
      itms => {
        if (itms != null) this.items = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.items = [];
        console.log(error);
      });
  }

  saveForm(): void {
    var flag: boolean;
    if (this.groupId == 0)
      return;
    this.item.fk_ReferenceGroupId = this.groupId;
    this.item.sortOrder = 0;

    var item = this.dataService.saveListReference(this.item as ListReference)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        //this.goBack();
        this.getListReferences();
        this.item = new ListReference();
        this.dataService.openSnackBar("Update succesful", "Select List");
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  gotoRoute(rt): void {
    this.router.navigate([rt]);
  }

}
