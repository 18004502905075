import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TokenStorage } from '../../login/tokenstorage.service';
//import { AlertService } from '../components/alert.service';
import { MooringApplicant } from '../../models/mooringapplicant';
import { DataService } from '../../data.service';
import { Lookup } from '../../models/lookup';
import { DataTransport } from '../../datatransport.provider';
import { ChildMessageRenderer } from "../../aggrid/child-message-renderer.component";
import { convertToParamMap } from '@angular/router/src/shared';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'waitlistgrid',
  templateUrl: 'waitlistgrid.component.html'
  //styleUrls: ['incidentlog.component.css']
})
export class WaitlistGridComponent {
  title = '';
  loading = false;
  reportingDay = moment();
  items: MooringApplicant[] = [];
  columnDefs;
  context;
  frameworkComponents;
  gridApi;
  rowSelection = 'single';
  selectedRows: any = null;
  showReports: boolean = false;

  constructor(
    private router: Router,
    private location: Location,
    private token: TokenStorage,
    private dataService: DataService,
    private data: DataTransport,
    private zone: NgZone
  ) {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      childMessageRenderer: ChildMessageRenderer
    };

    this.columnDefs = [
      {
        headerName: 'No.', field: 'placenumber', minWidth: 55, maxWidth: 75,
        cellRenderer: (params) => {
          var link = document.createElement('a');
          link.href = '#';
          link.innerText = params.value;
          link.addEventListener('click', (e) => {
            e.preventDefault();
            this.tempNavigate('/mooring/applicant/' + params.data.applicantId);
          });
          return link;
        }
      },
      { headerName: 'Applicant', field: 'fullName', minWidth:125, maxWidth: 200 },
      { headerName: 'Main Phone', field: 'phone1', minWidth: 125, maxWidth: 125 },
      {
        headerName: 'Apply Date', field: 'applicationDate', minWidth: 75, maxWidth: 125,
        cellRenderer: (params) => {
          if (params.value) {
            return moment(params.value).format("MM/DD/YYYY");
          }
          return "";
        }

      },
      {
        headerName: 'Last Renewed', field: 'lastRenewed', minWidth: 75, maxWidth: 125,
        cellRenderer: (params) => {
          if (params.value) {
            return moment(params.value).format("MM/DD/YYYY");
          }
          return "";
        }
      },
      { headerName: 'Boat Name', field: 'boatName', minWidth:125, maxWidth: 150 },
      { headerName: 'Length', field: 'boatLength', minWidth: 75, maxWidth: 90 },
      { headerName: 'Status', field: 'applicationStatus', minWidth: 100, maxWidth: 125 }
    ];
  }

  ngOnInit(): void {
    //this.getLogHeader();
    //let currentUser = this.token.getUser();
    this.getMooringApplicants();

  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    setTimeout(function () {
      params.api.resetRowHeights();
    }, 300);
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  onSelectionChanged() {
    //console.log(event);
    this.selectedRows = this.gridApi.getSelectedRows();
  }


  getMooringApplicants(): void {
    this.loading = true;

    this.dataService.getMooringApplicants('Waiting')
      .subscribe(
      itms => {
        if (itms != null) this.items = itms;
        this.loading = false;

      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.items = [];
        this.loading = false;
        console.log(error);
      });
  }


  //goToReport(): void {
  //  if (this.selectedRows != null && this.selectedRows.length > 0) {
  //    var url = '/log/incident/' + this.selectedRows[0].logIncidentId + '/report';
  //    this.data.storage = this.selectedRows[0];
  //    this.router.navigate([url]);
  //  }
  //}

  filterChanged(val) {
    this.gridApi.setQuickFilter(val);
  }

  goBack(): void {
    this.location.back();
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }
}
