export class OnSceneWeather {
  logWeatherId: number;
  fk_LogHeaderId: number;
  reportTime: string;
  station: string;
  sky: string;
  wind: string;
  visibility: string;
  airTempature: number;
  waterTempature: number;
  seas: string;
  barometer: string;
}
