//import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
//import './rxjs-extensions';
import { MomentModule } from 'ngx-moment';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatToolbarModule,
  MatTooltipModule,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material';

import { AgGridModule } from 'ag-grid-angular';

//components
import { MooringNavBarComponent } from './mooring-navbar.component';

//import { DataService } from '../data.service';
import { MooringRoutingModule } from './mooring-routing.module';
import { MooringTreeModule } from "../components/mooringtree.module";

import { MooringListComponent } from './mooringlist.component';
import { MooringViewComponent } from './mooringview.component';
import { PersonComponent } from './person.component';
import { AddressComponent } from './address.component';
import { BoatComponent } from './boat.component';
import { MooringLeaseComponent } from './mooringlease.component';
import { LeaseFeesComponent } from './leasefees.component';
import { WaitlistComponent } from './applicant/waitlist.component';
import { WaitlistGridComponent } from './applicant/waitlistgrid.component';
import { MooringTreeViewComponent } from './mooringtreeview.component';
import { LeaseTypesComponent } from './setup/leasetypes.component';
import { FeeTypesComponent } from './setup/feetypes.component';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatToolbarModule,
    MatTooltipModule,
  ]
})
export class MyMaterialModule { }

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    MomentModule,
    HttpClientModule,
    MyMaterialModule,
    AgGridModule.withComponents([]),
    MooringRoutingModule,
    MooringTreeModule
  ],
  declarations: [
    MooringNavBarComponent,
    MooringListComponent,
    MooringViewComponent,
    PersonComponent,
    AddressComponent,
    BoatComponent,
    MooringLeaseComponent,
    WaitlistComponent,
    WaitlistGridComponent,
    MooringTreeViewComponent,
    LeaseTypesComponent,
    FeeTypesComponent,
    LeaseFeesComponent
  ]
  //providers: [
  //  DataService
  //]
})
export class MooringModule { }
