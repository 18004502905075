export class IncidentSearchFilter {
  fromDate: Date;
  toDate: Date;
  departmentId: number;
  incidentCategory: string = '';
  searchFor: string = '';
  searchIncidents: boolean;
  searchReports: boolean;
  searchViolations: boolean;
}
