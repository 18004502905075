export class Boat {
  boatId: number;
  fk_OwnerId: number;
  boatName: string;
  portCity: string;
  portStateProv: string;
  registration: string;
  documentNo: string;
  commercial: boolean;
  manufacturer: string;
  boatYear: number;
  boatModel: string;
  boatType: string;
  boatLength: number;
  boatColor: string;
  boatDescription: string;
  fuelType: string;
  engine: string;
  hP: string;
  beamWidth: number;
  draft: number;
  pulpit: boolean;
  swimPlatform: boolean;
  fishCar: boolean;
  portableHead: boolean;
  holdingTank: boolean;

  createdBy: string;
  modifiedBy: string;
  createdDate: Date;
  modifiedDate: Date;
  //model
}
