export class Person {
  personId: number;
  personType: string;
  lastName: string;
  firstName: string;
  middleName: string;
  knownAs: string;
  title: string;
  resident: boolean;
  taxpayer: boolean;
  phone1: string;
  phone2: string;
  email1: string;
  webAddress: string;
  notes: string;
  associationId: number;
}
