import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; //, HttpErrorResponse, HttpHeaders, HttpParams
import { Observable, of } from 'rxjs'; //, throwError
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material';
import { MooringTreeView } from '../models/mooringtreeview';
import { MooringTreeSelected } from '../models/mooringtreeselected';

@Injectable() export class MooringTreeService {

  private apiUrl = environment.api;
  public observableTreeView: BehaviorSubject<MooringTreeView[]>;
  public observableSelected: BehaviorSubject<MooringTreeSelected>;
  private treeView: MooringTreeView[] = [];
  private nodeSelected: MooringTreeSelected = null;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    this.observableTreeView = new BehaviorSubject<MooringTreeView[]>(this.treeView);
    this.observableSelected = new BehaviorSubject<MooringTreeSelected>(this.nodeSelected);

  }

  treeviewEventChange() {
    this.observableTreeView.next(this.treeView);
  }
  nodeSelectedEvent() {
    this.observableSelected.next(this.nodeSelected);
  }

  //getMooringTreeView(leaseYear: number, leaseTypeId: number): Observable<MooringTreeView[]> {
  //  let url = `${this.apiUrl}/mooring/tree/${leaseYear}/${leaseTypeId}`;
  //  return this.http.get<MooringTreeView[]>(url);
  //}

  refreshMooringTreeView(leaseYear: number, leaseTypeId: number) {
    let url = `${this.apiUrl}/mooring/tree/${leaseYear}/${leaseTypeId}`;
    this.http.get<MooringTreeView[]>(url).subscribe(itm => {
      this.treeView = itm;
      this.treeviewEventChange();
    })
  }

  selectMooringTreeNode(node: MooringTreeSelected) {
    this.nodeSelected = node;
    this.nodeSelectedEvent();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


} 
