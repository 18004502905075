import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: 'child-cell',
  template: `<span>
    <button mat-button (click)="returnToParent('edit')" title="Edit" class="mat-button-grid mr-1"><span class="fas fa-edit"></span></button>
    <button mat-button *ngIf="isVisible.schedule" (click)="returnToParent('schedule')" title="Schedule" class="mat-button-grid mr-1"><span class="fas fa-calendar-alt"></span></button>
    </span>`,
  styles: [
    `.btn {
            line-height: 0.5
        }`
  ]
})
export class ChildMessageRenderer implements ICellRendererAngularComp {
  public params: any;
  
  isVisible = {
    edit: true,
    schedule: false
  };

  agInit(params: any): void {
    this.params = params;
    if (this.params.context.componentParent.constructor.name == "HouseStaffListComponent") {
      this.isVisible.schedule = true;
    }
  }

  public returnToParent(caller) {
    this.params.context.componentParent.methodFromParent(caller, this.params)
  }

  refresh(): boolean {
    return false;
  }
}
