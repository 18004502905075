import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TokenStorage } from '../../login/tokenstorage.service';
import { MooringApplicant } from '../../models/mooringapplicant';
import { DataService } from '../../data.service';
import { Lookup } from '../../models/lookup';
import { LookupWithParent } from '../../models/lookupwithparent';
import { UserProfile } from 'src/app/models/userprofile';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';
import { DataTransport } from '../../datatransport.provider';

@Component({
  moduleId: module.id,
  selector: 'waitlist-form',
  templateUrl: 'waitlist.component.html'
})
export class WaitlistComponent {
  title = '';
  item: MooringApplicant = null;
  userList: Lookup[] = [];
  geoPositionLabel = '';
  userName = '';
  userLevel: number = null;
  currentDate: number = Date.now();
  applicationDateString = "";
  lastRenewedString = "";
  completeDateString = "";
  boatTypeList: Lookup[] = [];
  applicationStatusList: Lookup[] = [
    { 'id': 'Waiting', 'label': 'Waiting' },
    { 'id': 'Assigned', 'label': 'Assigned' },
    { 'id': 'Declined', 'label': 'Declined' },
    { 'id': 'Expired', 'label': 'Expired' }
    ];

  constructor(
    private router: Router, 
    private token: TokenStorage,
    private dataService: DataService,
    private location: Location,
    private snackBar: MatSnackBar,
    private data: DataTransport,
    private route: ActivatedRoute
  ) {
    this.userName = this.token.getUser().firstName + ' ' + this.token.getUser().lastName;
    this.userLevel = this.token.getUser().userLevel;
  }

  ngOnInit(): void {
    this.item = new MooringApplicant();
    //const id = +this.route.snapshot.paramMap.get('id');
    this.getBoatTypeList();
    this.getMooringApplicant();
  
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getBoatTypeList(): void {
    this.dataService.getListReferenceLookups('boat_type')
      .subscribe(
      itms => {
        if (itms != null) this.boatTypeList = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.boatTypeList = [];
        console.log(error);
      });
  }

  getMooringApplicant(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id === 0)
      return;
    this.dataService.getMooringApplicantById(id)
      .subscribe(
      itms => {
        if (itms != null) {
          if (itms.applicationDate != null) {
            this.applicationDateString = moment(itms.applicationDate).format('YYYY-MM-DD');
          }
          if (itms.lastRenewed != null) {
            this.lastRenewedString = moment(itms.lastRenewed).format('YYYY-MM-DD');
          }
          if (itms.completeDate != null) {
            this.completeDateString = moment(itms.completeDate).format('YYYY-MM-DD');
          }
          this.item = itms;
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }


  saveForm(): void {

    var flag: boolean;
    if (moment(this.applicationDateString, 'YYYY-MM-DD', true).isValid()) {
      this.item.applicationDate = moment(this.applicationDateString).toDate();
    } else { this.item.applicationDate = null; }
    if (moment(this.lastRenewedString, 'YYYY-MM-DD', true).isValid()) {
      this.item.lastRenewed = moment(this.lastRenewedString).toDate();
    } else { this.item.lastRenewed = null; }
    //if (moment(this.completeDateString, 'YYYY-MM-DD', true).isValid()) {
    //  this.item.completeDate = moment(this.completeDateString).toDate();
    //} else { this.item.completeDate = null; }

    var item = this.dataService.saveMooringApplicant(this.item as MooringApplicant)
      .subscribe(result => {
        // Handle result
        //if (result.applicationDate != null) {
        //  this.applicationDateString = moment(result.applicationDate).format('YYYY-MM-DD');
        //}
        //if (result.lastRenewed != null) {
        //  this.lastRenewedString = moment(result.lastRenewed).format('YYYY-MM-DD');
        //}
        //if (result.completeDate != null) {
        //  this.completeDateString = moment(result.completeDate).format('YYYY-MM-DD');
        //}
        //this.item = result;
        this.openSnackBar("Update succesful", "Applicant");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  onLocationRequestedChange(val): void {
    this.item.slipRequested = val;
  }

  saveApplicantStatus(): void {
    this.openSnackBar("Feature not enabled", "Info");
  }

  //goToReport(id: number): void {
  //  if (id === 0) {
  //    var url = '/log/incident/' + this.item.logIncidentId + '/report';
  //  } else {
  //    var url = '/log/incident/' + this.item.logIncidentId + '/report/' + id;
  //  }
  //  this.data.storage = this.item;
  //  this.router.navigate([url]);
  //}

  goBack(): void {
    this.location.back();
  }
}
