import { Injectable } from '@angular/core';

@Injectable()
export class DataTransport {

  public storage: any;

  public constructor() { }

}
