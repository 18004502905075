import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MooringNavBarComponent } from './../mooring-navbar.component';
import { DataService } from '../../data.service';
import { LeaseType } from '../../models/leasetype';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { FeeType } from '../../models/feetype';


@Component({
  moduleId: module.id,
  selector: 'leasetypes',
  templateUrl: 'leasetypes.component.html'
  //styleUrls: ['mooringlist.component.css']
})
export class LeaseTypesComponent {
  title = 'Lease Types';
  items: LeaseType[] = [];
  private feeData: FeeType[] = [];
  feeItems: FeeType[] = [];
  private gridApi;
  private columnDefs;
  private context;
  private frameworkComponents;
  private feeGridApi;
  private feeColumnDefs;
  loading = false;
  leaseTypeId: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private location: Location,
    private zone: NgZone
  ) {
    this.context = { componentParent: this };



    this.columnDefs = [
      //{
      //  headerName: 'No.', field: 'leaseTypeId', minWidth: 175, maxWidth: 200,
      //  cellRenderer: (params) => {
      //    var link = document.createElement('a');
      //    link.href = '#';
      //    link.innerText = params.data.leaseType;
      //    link.addEventListener('click', (e) => {
      //      e.preventDefault();
      //      this.leaseSelectEvent(params.value);
      //    });
      //    return link;
      //  }
      //},
      { headerName: 'Name', field: 'leaseType', minWidth: 175, maxWidth: 200 },
      { headerName: 'Description', field: 'description', minWidth: 175, maxWidth: 250 },
      { headerName: 'Duration', field: 'leaseLength', minWidth: 85, maxWidth: 125 },
      { headerName: 'Unit', field: 'lengthUM', minWidth: 85, maxWidth: 125 },
      {
        headerName: 'Renewable', field: 'renewable', minWidth: 90, maxWidth: 90,
        cellRenderer: (params) => {
          if (params.value || params.data.hasViolation) {
            return 'Yes';
          }
          return "";
        }
      }
    ];

    this.feeColumnDefs = [
      { headerName: 'Fee Name', field: 'feeName', minWidth: 175, maxWidth: 200 },
      { headerName: 'Type', field: 'feeType', minWidth: 125, maxWidth: 125 },
      { headerName: 'Description', field: 'description', minWidth: 175, maxWidth: 250 },
      { headerName: 'Amount', field: 'defaultAmount', minWidth: 100, maxWidth: 125 }
    ];
  }

  ngOnInit(): void {
    this.getLeaseTypes();
    //this.getFeeTypes();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

  }
  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onFeeGridReady(params) {
    this.feeGridApi = params.api;
    //this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

  }
  onFeeGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onRowClicked(event: any) {
    console.log(event.data.leaseTypeId);
    this.leaseTypeId = event.data.leaseTypeId;
    this.refreshFees();
  }

  getLeaseTypes(): void {
    this.dataService.getLeaseTypes(true)
      .subscribe(
      itms => {
        if (itms != null) this.items = itms;
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.items = [];
        console.log(error);
      });
  }

  //NOT used
  getFeeTypes(): void {
    this.dataService.getFeeTypes(false)
      .subscribe(
      itms => {
        if (itms != null) {
          this.feeData = itms;
          this.refreshFees1();
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.feeData = [];
        this.feeItems = [];
        console.log(error);
      });
  }

  //NOT used
  refreshFees1(): void {
    if (this.leaseTypeId > 0) {
      this.feeItems = [];
      for (const d of this.feeData) {
        //if()
        this.feeItems.push(d);
      }
    } else {
      this.feeItems = [];
    }
  }

  refreshFees(): void {
    if (this.leaseTypeId > 0) {
      this.feeItems = [];
      for (const d of this.items) {
        if (d.leaseTypeId == this.leaseTypeId) {
          if (d.fees && d.fees != null) {
            this.feeItems = [...d.fees];
          }
          break;
        }
      }
    } else {
      this.feeItems = [];
    }
  }

  leaseSelectEvent(id): void {
    console.log(id);
  }

  tempNavigate(url: string): void {
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  goBack(): void {
    this.location.back();
  }
}
