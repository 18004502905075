import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FeeType } from '../models/feetype';
import { Fee } from '../models/fee';
import { LeaseAccountModel } from '../models/leaseaccountmodel';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';
import { MatSnackBar } from '@angular/material';
import { DataTransport } from '../datatransport.provider';
//
import { MooringTreeService } from "../components/mooringtree.service";
import { MooringTreeSelected } from '../models/mooringtreeselected';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'app-leasefeesform',
  templateUrl: 'leasefees.component.html',
})
export class LeaseFeesComponent {
  title = '';
  feeId:number = 0;
  personId: number = 0
  boatId: number = 0;
  leaseId: number = 0;
  item: Fee = null;
  items: LeaseAccountModel[] = [];
  feeTypeList: FeeType[] = [];
  showPriorYears: boolean = false;
  columnDefs;
  context;
  //frameworkComponents;
  gridApi;
  rowSelection = 'single';
  selectedRows: any = null;
  currentBalance: 0;

  private nodeSelectedSubscription: Subscription;
  @Input() IsChild: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private dataService: DataService,
    private treeService: MooringTreeService,
    private location: Location,
    private data: DataTransport
  ) {
    const id = +this.route.snapshot.paramMap.get('id');
    if (id && id > 0) {
      this.personId = id;
      this.boatId = +this.route.snapshot.paramMap.get('boatId');
      this.leaseId = +this.route.snapshot.paramMap.get('leaseId');
    }

    this.context = { componentParent: this };
    //this.frameworkComponents = {
    //  childMessageRenderer: ChildMessageRenderer
    //};

    this.columnDefs = [
      { headerName: 'Type', field: 'detailType', minWidth: 100, maxWidth: 150 },
      { headerName: 'Amount', field: 'amount', minWidth: 90, maxWidth: 125, cellClass: "ag-number" },
      {
        headerName: 'Due/Paid', field: 'entryDate', minWidth: 125, width: 125,
        cellRenderer: (params) => {
          if (params.value) {
            return moment(params.value).format("MM/DD/YYYY");
          }
          return "";
        }
      },
      { headerName: 'Balance', field: 'balanceAmount', minWidth: 90, width: 125, cellClass: "ag-number" },
      { headerName: 'Vessel', field: 'vessel', minWidth: 125, maxWidth: 175 },
      { headerName: 'Name', field: 'detailLastName', minWidth: 125, maxWidth: 175 },
      { headerName: 'Description', field: 'description', minWidth: 125, maxWidth: 175 }
    ];
  }

  ngOnInit(): void {
    this.nodeSelectedSubscription = this.treeService.observableSelected
      .subscribe(item => {
        this.nodeSelectedEvent(item as MooringTreeSelected);
      });

    if (this.data.storage != undefined && this.data.storage.title != null) {
      this.title = "Fees - " + this.data.storage.title;
    } else {
      this.title = "Fees";
    }
    this.item = new Fee();
    this.getFeeTypes();
    //this.getLeaseAccounts();

  }

  ngOnDestroy() {
    // Unsubscribe when the component is destroyed
    this.nodeSelectedSubscription.unsubscribe();
  }

  nodeSelectedEvent(node: MooringTreeSelected): void {
    if (node == null)
      return;
    if (node.personId > 0) {
      this.personId = node.personId;
      this.boatId = node.boatId;
      this.leaseId = node.leaseId;
      this.getLeaseAccounts();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(e) {
    this.selectedRows = this.gridApi.getSelectedRows();
    if (this.selectedRows != null && this.selectedRows.length > 0) {
      this.item = this.selectedRows[0];
    }
  }

  getFeeTypes(): void {
    this.dataService.getFeeTypes(false)
      .subscribe(
      itms => {
        if (itms != null) {
          this.feeTypeList = itms;
        }
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        this.feeTypeList = [];
        console.log(error);
      });
  }

  getLeaseAccounts(): void {
    //if (this.feeId === 0)
    //  return;

    let leaseYear = 0;
    if (this.showPriorYears == false) {
      leaseYear = 2019;
    }
    this.dataService.getLeaseAccounts(this.personId, this.boatId, this.leaseId, leaseYear)
      .subscribe(
      itms => {
        if (itms != null) this.items = itms;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.items = null;
        console.log(error);
      });
  }

  saveForm(): void {
    //this.item.fk_OwnerId = this.personId;
    //var item = this.dataService.saveBoat(this.personId, this.item as Boat)
    //  .subscribe(result => {
    //    // Handle result
    //    //console.log(result)
    //    //this.goBack();
    //    this.openSnackBar("Update succesful", "Boat");
    //  },
    //  error => {
    //    this.openSnackBar("error.message", "Error");
    //    console.log(error);
    //  },
    //  () => {
    //    // 'onCompleted' callback.
    //    // No errors, route to new page here
    //  });
  }

  clearForm(): void {
    
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  goBack(): void {
    this.location.back();
  }
}
