import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Address } from '../models/address';
import { DataService } from '../data.service';
import { Lookup } from '../models/lookup';
import { MatSnackBar } from '@angular/material';
import { DataTransport } from '../datatransport.provider';

@Component({
  moduleId: module.id,
  selector: 'address-form',
  templateUrl: 'address.component.html',
})
export class AddressComponent {
  title = '';
  item: Address = null;
  addressId: number = 0;
  countryCodeList: Lookup[] = [
    { 'id': 'US', 'label': 'United States' }
  ];
  addressTypeList: Lookup[] = [
    { 'id': 'Home', 'label': 'Home' },
    { 'id': 'Work', 'label': 'Work' },
    { 'id': 'Seasonal', 'label': 'Seasonal' }
  ];

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private dataService: DataService,
    private location: Location,
    private data: DataTransport
  ) { }

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.addressId = +this.route.snapshot.paramMap.get('addressId');
    if (id === 0) {
      //if (this.data.storage == undefined || this.data.storage == null) {
      this.router.navigate(['//moorings']);
      return;
    }
    if (this.data.storage != undefined && this.data.storage.title != null) {
      this.title = this.data.storage.title;
    } else {
      this.title = "Address";
    }
    this.item = new Address();
    this.item.countryCode = 'US';
    this.getAddress();


  }

  getAddress(): void {
    if (this.addressId === 0)
      return;
    const id = +this.route.snapshot.paramMap.get('id');
    this.dataService.getAddressById(id, this.addressId)
      .subscribe(
      itm => {
        if (itm != null) this.item = itm;
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.item = null;
        console.log(error);
      });
  }

  saveForm(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.item.fk_PersonId = id;
    var item = this.dataService.saveAddress(id, this.item as Address)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        //this.goBack();
        this.openSnackBar("Update succesful", "Address");
      },
      error => {
        this.openSnackBar("error.message", "Error");
        console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  goBack(): void {
    this.location.back();
  }
}
