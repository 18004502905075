import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/not-found.component';
import { DashboardComponent } from './dashboard/dashboardcomponent';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { IncidentStatsComponent } from './reports/incidentstats.component';
import { IncidentSearchComponent } from './reports/incidentsearch.component';
import { UserLogComponent } from './user/userlog.component';
import { UserProfileComponent } from './user/userprofile.component';
import { ListReferenceComponent } from './settings/listreference.component';
import { ChangePasswordComponent } from 'src/app/login/changepassword.component';


const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'dashboard/:flag', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'incident/stats', component: IncidentStatsComponent, canActivate: [AuthGuard] },
  { path: 'incident/search', component: IncidentSearchComponent, canActivate: [AuthGuard] },
  { path: 'login/:flag', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'user/list', component: UserLogComponent, canActivate: [AuthGuard] },
  { path: 'user/:id', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'user/account/changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'settings/selectlists', component: ListReferenceComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { enableTracing: false } // <-- debugging purposes only
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
