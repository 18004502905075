export class AnimalsInShelter {
  animalsInShelterId: number;
  species: string;
  animalName: string;
  dateReceived: Date;
  dateDeparted: Date;
  notes: string;
  breed: string;
  age: number;
  neutered: boolean;
  microchipped: boolean;
  vaccinated: boolean;
  stray: boolean;
  locationFound: string;
  disposition: string;
}
