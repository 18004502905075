import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
//import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService } from './alert.service';
import { DataService } from '../data.service';
import { DataTransport } from '../datatransport.provider';
import { FileUploadService } from '../fileupload.service';
import { Lookup } from '../models/lookup';
import { MatSnackBar } from '@angular/material';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  moduleId: module.id,
  selector: 'file-form',
  templateUrl: 'file.component.html',
  //styleUrls: ['logheader.component.css'],
})
export class FileComponent {
  statusLabel = 'Ready';
  title = '';
  fileToUpload: File = null;
  personId: number;
  boatId: number;
  leaseId: number;
  fileList: string[] = [];
  fileLabel = '';
  @ViewChild('myFileInput')
  myFileInput: any;
  uploading = false;

  constructor(
    private router: Router,
    //private route: ActivatedRoute,
    private location: Location,
    
    private dataService: DataService,
    private data: DataTransport,
    private fileUpload: FileUploadService,
    private snackBar: MatSnackBar,
    private ng2ImgMax: Ng2ImgMaxService
  ) { }

  ngOnInit(): void {
    if (this.data.storage == undefined || this.data.storage.personId == null) {
      this.router.navigate(['/moorings']);
    } else {
      this.personId = this.data.storage.personId;
      this.boatId = this.data.storage.boatId;
      this.leaseId = this.data.storage.leaseId;
      this.title = this.data.storage.title;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFile() {
    this.fileUpload.personId = this.personId.toString();
    this.fileUpload.boatId = this.boatId.toString();
    this.fileUpload.leaseId = this.leaseId.toString();

    this.uploading = true;
    this.statusLabel = 'Uploading...';
    if (this.fileToUpload.type == "image/jpeg" || this.fileToUpload.type == "image/png") {
      if (this.fileToUpload.size > 250000) {
        this.resizeAndUpload();
      } else {
        this.postUpload();
      }
    }
  }

  compressAndUpload() {
    this.ng2ImgMax.compressImage(this.fileToUpload, 0.25).subscribe(
      result => {
        this.fileToUpload = new File([result], result.name);
        //this.getImagePreview(this.uploadedImage);
        this.postUpload();
      },
      error => {
        console.log('😢 Oh no!', error);
        //upload file anyway
        this.postUpload();
      }
    );
  }

  resizeAndUpload() {
    this.ng2ImgMax.resizeImage(this.fileToUpload, 1280, 10000).subscribe(
      result => {
        this.fileToUpload = new File([result], result.name);
        //this.getImagePreview(this.uploadedImage);
        this.postUpload();
      },
      error => {
        console.log('😢 Oh no!', error);
        //upload file anyway
        this.postUpload();
      }
    );
  }

  postUpload(): void {
    this.fileUpload.postFile(this.fileToUpload, this.fileLabel)
      .subscribe(data => {
        this.uploading = false;
        this.statusLabel = 'Complete';
        this.fileList.push(data);
        this.fileToUpload = null;
        this.fileLabel = '';
        this.myFileInput.nativeElement.value = "";
        this.openSnackBar("Update succesful", "File");
      }, error => {
        this.openSnackBar("error.message", "Error");
        this.uploading = false;
        this.statusLabel = 'Error';
        console.log(error);
      });
  }

  goBack(): void {
    this.location.back();
  }
}
