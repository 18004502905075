import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AuthenticationService } from './authentication.service';
import { TokenStorage } from './tokenstorage.service';
import { UserProfile } from '../models/userprofile'
//import { UserLoginContainer } from '../models/userlogincontainer'
import { MatSnackBar } from '@angular/material';
import { ConsoleLoggerService } from '../components/console-logger.service';

@Component({
  moduleId: module.id,
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private token: TokenStorage,
    private snackBar: MatSnackBar,
    private logger: ConsoleLoggerService) { }

  username: string;
  password: string;
  loading = false;
  loginMessage = '';

  ngOnInit(): void {
    const flag = +this.route.snapshot.paramMap.get('flag');
    if (flag==1) {
      this.loginMessage = 'Unauthorized';
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  login(): void {
    this.loading = true;
    this.authService.login(this.username, this.password)
      .subscribe(result => {
        // Handle result
        console.log(result);
        this.loading = false;
        if (result != null) {
          this.token.saveToken(result.token);
          this.token.saveUser(result.user);
          this.router.navigate(['dashboard/1']);
        }
      },
      error => {
        this.openSnackBar("error.message", "Error");
        this.loading = false;
        this.logger.error(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
        this.loading = false;
      });
  }
}
