export class Fee {
  feeId: number;
  source: string;
  sourceId: number;
  fk_FeeTypeId: number;
  feeName: string;
  description_Override: string;
  feeAmount: number;
  dueDate: Date;
  recordDate: Date;
  createdBy: string;
  modifiedBy: string;
  createdDate: Date;
  modifiedDate: Date;
  //
  //moduleType: string;
}
