import { Injectable } from '@angular/core';
import { LogHeader } from '../models/logheader';
import { DataService } from '../data.service';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
const CACHE_SIZE = 1;

@Injectable()
export class LogHeaderService {
  private cache$: Observable<LogHeader>;
  private reportingDay: Date;

  constructor(private dataService: DataService) { }

  setReportingDay(dt: Date): void {
    this.reportingDay = dt;
    this.cache$ = null;
  }

  getLogHeader() {
    if (!this.cache$) {
      this.cache$ = this.getLogHeaderInternal().pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cache$;
  
  }

  private getLogHeaderInternal(): Observable<LogHeader> {
    if (this.reportingDay == null) {
      this.reportingDay = moment().toDate();
    }

    return this.dataService.getLogHeader(this.reportingDay);
    //  .pipe(
    //  map(response => response.value)
    //)
  }
}

