import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { AlertService } from '../components/alert.service';
import { MooringNavBarComponent } from './mooring-navbar.component';
import { DataService } from '../data.service';
import { DataTransport } from '../datatransport.provider';
import { MooringView } from '../models/mooringview';
import { Note } from '../models/note';
import { FileListModel } from '../models/filelistmodel';

import { Observable } from 'rxjs/Observable';
//import { mergeMap  } from 'rxjs/operators';
//import 'rxjs/add/operator/mergeMap';
//import { forkJoin } from 'rxjs/observable/forkJoin';


@Component({
  moduleId: module.id,
  selector: 'mooringview',
  templateUrl: 'mooringview.component.html',
  styleUrls: ['mooringview.component.css']
})
export class MooringViewComponent {
  title = '';
  item: MooringView;
  showMore: boolean = false;
  noteList: Note[] = [];
  fileList: FileListModel[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private dataService: DataService,
    private data: DataTransport
  ) { }

  ngOnInit(): void {
    this.getMooringView();
  }

  //getMooringView(): void {
  //  const id = +this.route.snapshot.paramMap.get('id');
  //  this.dataService.getMooringViewById(id)
  //    .flatMap(itm => {
  //      this.item = itm;
  //      return this.dataService.getNotesForPerson(this.item.personId);
  //    })
  //    .subscribe(itm => this.noteList = itm);
  //}

  getMooringView(): void {
    const id = +this.route.snapshot.paramMap.get('id');

    this.dataService.getMooringViewById(id)
      .subscribe(itm => {
        this.item = itm;
        //let rxNote = this.dataService.getNotesForPerson(this.item.personId);
        //let rxFile = this.dataService.getFilesForPerson(this.item.personId);

        //forkJoin(rxNote, rxFile).subscribe(([result1, result2]) => {
        //  this.noteList = result1;
        //  this.fileList = result2;
        //})

        this.dataService.getNotesForPerson(this.item.personId)
          .subscribe(result1 => {
            this.noteList = result1;
          });
        this.dataService.getFilesForPerson(this.item.personId)
          .subscribe(result2 => {
            this.fileList = result2;
          });
      },
      error => {
        this.dataService.openSnackBar("error.message", "Error");
        console.log(error);
      });
  }

  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }

  addNote(): void {
    //const id = +this.route.snapshot.paramMap.get('id');
    var txt = this.item.fullName;
    this.data.storage = { personId: this.item.personId, boatId: this.item.boatId, leaseId: this.item.leaseId, title: txt };
    this.router.navigate([`mooring/${this.item.leaseId}/note`]);
  }

  addFile(): void {
    var txt = this.item.fullName;
    this.data.storage = { personId: this.item.personId, boatId: this.item.boatId, leaseId: this.item.leaseId, title: txt };
    this.router.navigate([`mooring/${this.item.leaseId}/file`]);
  }

  @ViewChild('downloadFileLink') private downloadFileLink: ElementRef;
  public async downloadFile(id: number, fileName: string): Promise<void> {
    var fn = '';
    const blob = await this.dataService.getFileDownload(id);

    const url = window.URL.createObjectURL(blob);

    const link = this.downloadFileLink.nativeElement;
    link.href = url;
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(url);

  }

  //getFileDownload(id: number): void {
  //  this.dataService.getFileDownload(id)
  //    .subscribe(data => this.downloadFile(data)),
  //    error => {
  //      //console.log("Error downloading the file.")
  //      this.alertService.error("Error downloading the file.");
  //      console.log(error);
  //    },
  //    e => {
  //      console.info("OK");
  //    }
  //}

  //downloadFile(data: any) {
  //  //var reader = new FileReader();
  //  //reader.readAsDataURL(data);
  //  var blob = new Blob([data], { type: 'application/octet-stream' });
  //  var url = window.URL.createObjectURL(blob);
  //  window.open(url);
  //  //reader.onloadend = function (e) {
  //  //  window.open(reader.result, 'File', 'width=20,height=10,toolbar=0,menubar=0,scrollbars=no');
  //}

  goBack(): void {
    this.location.back();
  }
}
